import { WatchCallback, ref, watch } from "vue";
import { useMainStore } from "@/stores/mainStore";
import { wrapWithLoading } from "@/utils";
import { Section } from "@/models";
import { SectionFilter } from "@/const";
import { useRoute } from "vue-router";

export default function useSections() {
  const mainStore = useMainStore();
  const route = useRoute();

  const sectionId = route.params.sectionId as string;
  const currentSection = ref(null as Section | null);

  const onSectionIdChange = (callback: WatchCallback<any>) =>
    watch(() => route.params.sectionId, callback, { immediate: true });

  const fetchSections = async (filters?: SectionFilter) => {
    await wrapWithLoading(async () => {
      await mainStore.getSections(filters);
    });
  };

  // Keep currentSection up to date
  onSectionIdChange(async (sectionId) => {
    if (sectionId) {
      currentSection.value = await mainStore.getSectionById(
        sectionId as string
      );
    } else {
      currentSection.value = null;
    }
  });

  return {
    currentSection, // Current section
    fetchSections, // Fetch sections
    sectionId, // Current section id from route, if any
    onSectionIdChange, // Watch for section id changes
  };
}
