import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["for"]
const _hoisted_2 = ["disabled", "id"]
const _hoisted_3 = { class: "flex flex-col items-center justify-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VideoPlusIcon = _resolveComponent("VideoPlusIcon")!
  const _component_LoadingSpinner = _resolveComponent("LoadingSpinner")!
  const _component_TCard = _resolveComponent("TCard")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("label", {
      class: _normalizeClass(["block w-min", [_ctx.loading ? 'cursor-wait' : 'cursor-pointer']]),
      for: _ctx.baseId
    }, [
      _createElementVNode("input", {
        disabled: _ctx.loading,
        class: "absolute invisible w-0 h-0",
        id: _ctx.baseId,
        type: "file",
        accept: "video/*",
        onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onFileChange($event)))
      }, null, 40, _hoisted_2),
      _createVNode(_component_TCard, { class: "flex flex-col items-center justify-center w-56 transition-colors duration-200 ease-out h-36 group hover:bg-slate-100" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_3, [
            (!_ctx.loading)
              ? (_openBlock(), _createBlock(_component_VideoPlusIcon, {
                  key: 0,
                  class: "z-10 duration-300 ease-out transform text-secondary group-hover:scale-110",
                  size: 80
                }))
              : (_openBlock(), _createBlock(_component_LoadingSpinner, { key: 1 }))
          ])
        ]),
        _: 1
      })
    ], 10, _hoisted_1)
  ]))
}