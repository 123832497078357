
import { defineComponent, PropType } from "@vue/runtime-core";
import { Section } from "@/models";
import draggable from "vuedraggable";
import DragVerticalIcon from "vue-material-design-icons/DragVertical.vue";
import { TButton, TRadio } from "@variantjs/vue";
import { mapStores } from "pinia";
import { useMainStore } from "@/stores/mainStore";

export type SortOption = "alphabetical" | "chrono" | "most_shared" | "custom";

export default defineComponent({
  name: "SectionSorter",
  props: {
    sections: {
      type: Array as PropType<Section[]>,
      required: true,
    },
    sortType: {
      type: String as PropType<SortOption>,
      default: "alphabetical",
    },
  },
  watch: {
    serializedSectionsAndSortType: {
      immediate: true,
      handler() {
        this.sortedSections = [...this.sections];
        this.dirtySortType = this.sortType;
      },
    },
  },
  data() {
    return {
      sortedSections: [] as Section[],
      dirtySortType: "" as SortOption,
    };
  },
  methods: {
    async onSave() {
      await this.mainStore.setSectionSorting(
        this.dirtySortType,
        this.sortedSections.map((s) => s.id)
      );
      // once saved, refresh the page
      window.location.reload();
    },
  },
  computed: {
    ...mapStores(useMainStore),
    thereAreChanges() {
      return (
        this.dirtySortType !== this.sortType ||
        this.sortedSections.some((s, i) => s.id !== this.sections[i].id)
      );
    },
    serializedSectionsAndSortType() {
      return JSON.stringify({
        sections: this.sections.map((s) => s.id),
        sortType: this.sortType,
      });
    },
  },
  components: { draggable, DragVerticalIcon, TRadio, TButton },
});
