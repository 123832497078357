
import { TButton, TInput } from "@variantjs/vue";
import { defineComponent, PropType } from "@vue/runtime-core";
import SendIcon from "vue-material-design-icons/Send.vue";
export default defineComponent({
  name: "CommentEditor",
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onCreateComment() {
      if (this.comment.trim().length > 0) {
        this.$emit("createComment", this.comment);
        this.comment = "";
      }
    },
  },
  data() {
    return {
      comment: "",
    };
  },
  computed: {},
  components: { TInput, TButton, SendIcon },
});
