import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["for"]
const _hoisted_2 = {
  style: {"min-width":"12rem","min-height":"12rem","max-width":"12rem","max-height":"12rem"},
  class: "relative overflow-hidden rounded-full cursor-pointer group"
}
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "absolute bottom-0 left-0 z-10 flex items-center w-full transition-all duration-300 ease-in-out bg-black bg-opacity-40 group-hover:bg-opacity-80 h-14" }
const _hoisted_5 = ["id"]
const _hoisted_6 = { class: "flex items-center w-full" }
const _hoisted_7 = { class: "flex space-x-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CameraIcon = _resolveComponent("CameraIcon")!
  const _component_UserAvatar = _resolveComponent("UserAvatar")!
  const _component_TButton = _resolveComponent("TButton")!
  const _component_MDDialog = _resolveComponent("MDDialog")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("label", { for: _ctx.baseId }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("img", {
          src: _ctx.urlWithFallback,
          alt: "Avatar",
          class: "transition-all duration-300 ease-in-out filter group-hover:brightness-90"
        }, null, 8, _hoisted_3),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_CameraIcon, {
            class: "z-20 mx-auto text-gray-200 transition-transform duration-300 ease-in-out transform group-hover:scale-110",
            size: "40"
          })
        ])
      ])
    ], 8, _hoisted_1),
    _createElementVNode("input", {
      class: "absolute invisible w-0 h-0",
      id: _ctx.baseId,
      type: "file",
      accept: "image/*",
      onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onFileChange($event)))
    }, null, 40, _hoisted_5),
    _createVNode(_component_MDDialog, {
      onCloseDialog: _cache[3] || (_cache[3] = ($event: any) => (_ctx.selectedFile = null)),
      open: _ctx.selectedFile !== null,
      large: true
    }, {
      title: _withCtx(() => [
        _createTextVNode("Anteprima")
      ]),
      content: _withCtx(() => [
        _createElementVNode("div", _hoisted_6, [
          (_ctx.selectedImageUrl)
            ? (_openBlock(), _createBlock(_component_UserAvatar, {
                key: 0,
                class: "mx-auto",
                size: 'xl',
                url: _ctx.selectedImageUrl
              }, null, 8, ["url"]))
            : _createCommentVNode("", true)
        ])
      ]),
      actions: _withCtx(() => [
        _createElementVNode("div", _hoisted_7, [
          _createVNode(_component_TButton, {
            disabled: _ctx.loading,
            class: "ml-auto",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.confirm()))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.loading ? "Attendi..." : "Carica"), 1)
            ]),
            _: 1
          }, 8, ["disabled"]),
          _createVNode(_component_TButton, {
            variant: 'icon',
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.selectedFile = null))
          }, {
            default: _withCtx(() => [
              _createTextVNode("Annulla")
            ]),
            _: 1
          })
        ])
      ]),
      _: 1
    }, 8, ["open"])
  ]))
}