<template>
  <div
    class="flex items-center"
    :class="{
      'flex-col space-y-6': orientation === 'vertical',
      'space-x-4': orientation === 'horizontal',
    }"
  >
    <p class="text-sm text-center">Affidabilità: {{ value }}%</p>
    <div
      :class="[
        orientation === 'vertical'
          ? 'slider-container-vertical'
          : 'slider-container-horizontal w-1/2',
      ]"
    >
      <div
        :class="[
          orientation === 'vertical'
            ? 'slider-track-vertical fact-check-bg-vertical'
            : 'slider-track-horizontal fact-check-bg-horizontal',
          'rounded-sm shadow-sm ',
        ]"
      >
        <div
          :class="[
            orientation === 'vertical'
              ? 'slider-thumb-vertical'
              : 'slider-thumb-horizontal',
            'shadow-sm',
          ]"
          :style="{
            [orientation === 'vertical' ? 'bottom' : 'left']:
              thumbPosition + '%',
            'background-color': thumbColor,
          }"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watch, onMounted, computed, PropType } from "vue";

export default {
  props: {
    value: {
      type: Number,
      required: true,
    },
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 100,
    },
    orientation: {
      type: String, // as PropType<"vertical"|"horizontal">,
      required: true,
    },
  },
  setup(props) {
    const thumbPosition = ref(0);

    const updateThumbPosition = () => {
      const percentage =
        ((props.value - props.min) / (props.max - props.min)) * 100;
      thumbPosition.value = percentage;
    };

    const thumbColor = computed(() => {
      const percentage = thumbPosition.value;

      // Define color stops in HSL
      const colorStops = [
        { h: 0, s: 100, l: 50 }, // red
        { h: 60, s: 100, l: 50 }, // yellow
        { h: 120, s: 100, l: 50 }, // green
      ];

      // Find the color stops to interpolate between
      let startIndex = 0;
      if (percentage >= 50) startIndex = 1;

      const startColor = colorStops[startIndex];
      const endColor = colorStops[startIndex + 1];

      // Interpolate between the two colors
      const t = (percentage - startIndex * 50) / 50;
      const h = startColor.h + t * (endColor.h - startColor.h);
      const s = startColor.s + t * (endColor.s - startColor.s);
      const l = startColor.l + t * (endColor.l - startColor.l);

      // Convert to CSS hsl() format
      return `hsl(${h + 2}, ${s + 10}%, ${l + 10}%)`;
    });

    watch(() => props.value, updateThumbPosition);

    onMounted(updateThumbPosition);

    return { thumbPosition, thumbColor };
  },
};
</script>

<style scoped>
.slider-container-vertical {
  height: 100px;
  position: relative;
}

.slider-container-horizontal {
  position: relative;
}

.slider-track-vertical {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  width: 5px;
  transform: translateX(-50%);
}

.slider-track-horizontal {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  height: 5px;
  transform: translateY(-50%);
}

.slider-thumb-vertical {
  position: absolute;
  left: 50%;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  transform: translateX(-50%);
  outline: 0.25px solid #fff;
}

.slider-thumb-horizontal {
  position: absolute;
  top: 50%;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  transform: translateY(-50%);
  outline: 0.25px solid #fff;
}
</style>
