
import { defineComponent, PropType } from "@vue/runtime-core";
import BaseView from "../../views/BaseView.vue";
import { mapStores } from "pinia";
import { useMainStore } from "../../stores/mainStore";
import { Post } from "../../models";
import PostList from "../PostList.vue";

export default defineComponent({
  name: "PostDetail",
  props: {
    postId: {
      type: String,
      required: true,
    },
  },
  async created() {
    this.loading = true;
    try {
      this.mainStore.getSections();
      await this.mainStore.getPostById(this.postId);
    } finally {
      this.loading = false;
    }
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {},
  computed: {
    ...mapStores(useMainStore),
    post() {
      return this.mainStore.postById(this.postId);
    },
  },
  components: { BaseView, PostList },
});
