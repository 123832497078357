
import { TButton, TInput } from "@variantjs/vue";
import { defineComponent, PropType } from "@vue/runtime-core";
import { mapStores } from "pinia";
import { useMainStore } from "../stores/mainStore";
export default defineComponent({
  name: "LoginView",
  props: {},
  data() {
    return {
      username: "",
      password: "",
      showError: false,
    };
  },
  methods: {
    async onSubmit() {
      try {
        const user = await this.mainStore.login(this.username, this.password);
        if (user.registration_complete) {
          this.$router.push("/");
        } else {
          this.$router.push("/me");
        }
      } catch (e: any) {
        if (e.response.status === 400) {
          this.showError = true;
        } else {
          throw e;
        }
      }
    },
  },
  computed: {
    ...mapStores(useMainStore),
  },
  components: { TInput, TButton },
});
