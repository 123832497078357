/* eslint-disable @typescript-eslint/no-explicit-any */
import { Comment, Post } from "./interfaces";
export const getBlankPost = (postType: "dissemination" | "personal"): Post => ({
  id: "",
  content: "",
  author: null as any,
  created_at: "",
  post_type: postType,
  section: null,
  sources: [],
});

export const getBlankPostComment = (
  content: string,
  comment_type: "regular" | "fact_check" = "regular"
): Comment => ({
  id: "",
  content,
  created_at: "",
  user: null as any,
  comment_type,
});

export const getSharedPost = (postId: string, content: string): Post => ({
  id: "",
  content,
  author: null as any,
  created_at: "",
  post_type: "personal",
  section: null,
  sources: [],
  shared: postId,
});
