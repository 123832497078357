
import { defineComponent, PropType } from "@vue/runtime-core";
import UserAvatar from "../UserAvatar.vue";
import { TInput } from "@variantjs/vue";
import { TButton } from "@variantjs/vue";
import MagnifyIcon from "vue-material-design-icons/Magnify.vue";
import PostOutlineIcon from "vue-material-design-icons/PostOutline.vue";
// eslint-disable-next-line @typescript-eslint/no-var-requires
const debounce = require("lodash.debounce");

import { useSearchStore } from "../../stores/searchStore";
import { storeToRefs } from "pinia";
import { ref } from "vue";
import { stripHtmlFromString } from "../../utils";
import { Post } from "../../models";

export default defineComponent({
  setup() {
    const searchStore = useSearchStore();
    const { query, results, loading, error, unifiedResults } =
      storeToRefs(searchStore);
    const { setQuery, setLoading, search } = searchStore;

    const debouncedSearch = debounce(search, 500);

    const updateQuery = (event: { target: { value: string } }) => {
      setQuery(event.target.value);
      showResults.value = query.value.length > 0;
      setLoading(true);
      debouncedSearch();
    };

    const showResults = ref(false);

    const onFocus = () => {
      if (query.value.length > 0) {
        showResults.value = true;
      }
    };

    const PAGE_SIZE = 2;
    const resultsPage = ref(1);

    const onMoreResults = () => {
      resultsPage.value += 1;
    };

    const resultTypeRouteMapping = {
      user: (userId: string) => ({
        name: "UserProfile",
        params: {
          userId,
        },
      }),
      post: (postId: string) => ({
        name: "PostDetail",
        params: {
          postId,
        },
      }),
      section: (sectionId: string) => ({
        name: "SectionHome",
        params: {
          sectionId,
        },
      }),
      subsection: (sectionId: string) => ({
        name: "SectionHome",
        params: {
          sectionId,
        },
      }),
    };

    const getPostPreview = (post: Post) => {
      const strippedText = stripHtmlFromString(post.content);
      const preview =
        strippedText.length > 100
          ? strippedText.slice(0, 100) + "..."
          : strippedText;
      return preview;
    };

    return {
      query,
      results,
      loading,
      error,
      updateQuery,
      unifiedResults,
      resultTypeRouteMapping,
      showResults,
      onFocus,
      PAGE_SIZE,
      resultsPage,
      onMoreResults,
      getPostPreview,
    };
  },
  components: { MagnifyIcon, TInput, UserAvatar, TButton, PostOutlineIcon },
});
