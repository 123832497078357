import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex items-start space-x-2" }
const _hoisted_2 = { class: "flex flex-col items-start" }
const _hoisted_3 = { class: "flex items-center space-x-2" }
const _hoisted_4 = { class: "font-medium transition-colors duration-75 ease-in-out hover:text-secondary-dark" }
const _hoisted_5 = { class: "text-xs opacity-60" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UserAvatar = _resolveComponent("UserAvatar")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_router_link, { to: _ctx.commentAuthorProfileRoute }, {
      default: _withCtx(() => [
        _createVNode(_component_UserAvatar, {
          size: 'sm',
          url: _ctx.comment.user.profile_picture
        }, null, 8, ["url"])
      ]),
      _: 1
    }, 8, ["to"]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_router_link, { to: _ctx.commentAuthorProfileRoute }, {
          default: _withCtx(() => [
            _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.comment.user.full_name), 1)
          ]),
          _: 1
        }, 8, ["to"]),
        _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.formattedTimestamp), 1)
      ]),
      _createElementVNode("p", null, _toDisplayString(_ctx.comment.content), 1)
    ])
  ]))
}