import { Section } from "../models/interfaces";

export const mockSections: Section[] = [
  {
    id: "1",
    name: "Medicina",
    parent: null,
    created_at: "2020-01-01T00:00:00.000Z",
  },
  {
    id: "2",
    name: "Sport",
    parent: null,
    created_at: "2020-01-01T00:00:00.000Z",
  },
  {
    id: "4",
    name: "Chimica",
    parent: null,
    created_at: "2020-01-01T00:00:00.000Z",
  },
  {
    id: "3",
    name: "Biologia",
    parent: null,
    created_at: "2020-01-01T00:00:00.000Z",
  },
  {
    id: "5",
    name: "Chimica inorganica",
    parent: "4",
    created_at: "2020-01-01T00:00:00.000Z",
  },
  {
    id: "6",
    name: "Industriale",
    parent: "4",
    created_at: "2020-01-01T00:00:00.000Z",
  },
  {
    id: "7",
    name: "Alimenti",
    parent: "4",
    created_at: "2020-01-01T00:00:00.000Z",
  },
];
