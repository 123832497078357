import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "relative block w-full transition duration-100 ease-in-out rounded shadow-sm focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50 disabled:opacity-50 disabled:cursor-not-allowed" }
const _hoisted_2 = ["for"]
const _hoisted_3 = {
  key: 0,
  class: "text-sm font-light text-danger-dark"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CalendarTodayIcon = _resolveComponent("CalendarTodayIcon")!
  const _component_CloseIcon = _resolveComponent("CloseIcon")!
  const _component_date_picker = _resolveComponent("date-picker")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", { for: _ctx.elementId }, [
      _createElementVNode("div", {
        class: _normalizeClass([{
          'border-danger-dark focus-within:border-danger-dark': _ctx.$slots.errors,
        }, ""])
      }, [
        _createElementVNode("label", {
          class: _normalizeClass([{ hidden: _ctx.proxyModelValue }, "absolute text-gray-400 transform -translate-y-1/2 top-1/2 left-3"])
        }, [
          _renderSlot(_ctx.$slots, "default")
        ], 2),
        _createVNode(_component_date_picker, {
          class: "w-full h-full",
          id: _ctx.elementId,
          onOpen: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onOpen())),
          open: _ctx.calendarOpen,
          onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onClose())),
          onFocus: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('focus'))),
          value: _ctx.proxyModelValue,
          "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.proxyModelValue) = $event)),
          type: "date",
          ref: 'calendar-' + _ctx.elementId,
          "show-second": false,
          formatter: _ctx.formatter,
          "title-time-format": 'DD-MM-YYYY',
          required: ""
        }, {
          "icon-calendar": _withCtx(() => [
            false
              ? (_openBlock(), _createBlock(_component_CalendarTodayIcon, { key: 0 }))
              : _createCommentVNode("", true)
          ]),
          "icon-clear": _withCtx(() => [
            _createVNode(_component_CloseIcon)
          ]),
          _: 1
        }, 8, ["id", "open", "value", "formatter"])
      ], 2),
      (_ctx.$slots.errors?.())
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _renderSlot(_ctx.$slots, "errors")
          ]))
        : _createCommentVNode("", true)
    ], 8, _hoisted_2)
  ]))
}