
import { defineComponent, PropType } from "@vue/runtime-core";
import { SelectableOption } from "../../utils";
import { TButton } from "@variantjs/vue";
import { v4 as uuid4 } from "uuid";
export default defineComponent({
  name: "SegmentedButtons",
  props: {
    modelValue: {
      type: String as PropType<string>,
      required: true,
    },
    options: {
      type: Array as PropType<SelectableOption<string>[]>,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      id: "a" + uuid4(),
    };
  },
  methods: {
    onInput(value: string, inputEvent: Event) {
      // eslint-disable-next-line @typescript-eslint/no-extra-semi
      (inputEvent.target as unknown as { checked: boolean }).checked = false;
      inputEvent.preventDefault();
      this.$emit("update:modelValue", value);
    },
  },
  computed: {},
  components: {
    // TButton
  },
});
