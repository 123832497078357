import { resolveComponent as _resolveComponent, withKeys as _withKeys, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex items-center space-x-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TInput = _resolveComponent("TInput")!
  const _component_SendIcon = _resolveComponent("SendIcon")!
  const _component_TButton = _resolveComponent("TButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_TInput, {
      onKeyup: _cache[0] || (_cache[0] = _withKeys(($event: any) => (_ctx.onCreateComment()), ["enter"])),
      variant: 'search',
      modelValue: _ctx.comment,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.comment) = $event)),
      placeholder: "Scrivi un commento...",
      disabled: _ctx.disabled
    }, null, 8, ["modelValue", "disabled"]),
    _createVNode(_component_TButton, {
      disabled: _ctx.disabled,
      variant: 'icon',
      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onCreateComment()))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_SendIcon, { class: "button-icon" })
      ]),
      _: 1
    }, 8, ["disabled"])
  ]))
}