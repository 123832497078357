import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "text-lg font-medium"
}
const _hoisted_2 = {
  key: 1,
  class: "text-base font-medium"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_MenuDownIcon = _resolveComponent("MenuDownIcon")!
  const _component_MenuUpIcon = _resolveComponent("MenuUpIcon")!
  const _component_TButton = _resolveComponent("TButton")!
  const _component_SectionListItem = _resolveComponent("SectionListItem", true)!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", {
      class: _normalizeClass(["flex items-center space-x-0.5", {
        'pl-4': _ctx.sub,
      }])
    }, [
      _createVNode(_component_router_link, {
        class: "hover-underline-animation hover:font-medium",
        to: { name: _ctx.routeName, params: { sectionId: _ctx.section.id } }
      }, {
        default: _withCtx(() => [
          (!_ctx.sub)
            ? (_openBlock(), _createElementBlock("h3", _hoisted_1, _toDisplayString(_ctx.section.name), 1))
            : (_openBlock(), _createElementBlock("h4", _hoisted_2, _toDisplayString(_ctx.section.name), 1))
        ]),
        _: 1
      }, 8, ["to"]),
      (_ctx.hasSubSections)
        ? (_openBlock(), _createBlock(_component_TButton, {
            key: 0,
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.expanded = !_ctx.expanded)),
            variant: 'icon'
          }, {
            default: _withCtx(() => [
              (!_ctx.expanded)
                ? (_openBlock(), _createBlock(_component_MenuDownIcon, { key: 0 }))
                : (_openBlock(), _createBlock(_component_MenuUpIcon, { key: 1 }))
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass([
        _ctx.expanded ? 'max-h-96' : 'max-h-0',
        'transition-all duration-300 ease overflow-hidden flex flex-col pt-6',
      ])
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.subSections, (subSection) => {
        return (_openBlock(), _createBlock(_component_SectionListItem, {
          sub: true,
          key: subSection.id,
          section: subSection
        }, null, 8, ["section"]))
      }), 128))
    ], 2)
  ]))
}