
import VideoPlayer from "./VideoPlayer.vue";
import { defineComponent, PropType } from "@vue/runtime-core";
import { Comment, Post } from "../../models/interfaces";
import UserAvatar from "../UserAvatar.vue";
import { mapStores } from "pinia";
import { useMainStore } from "../../stores/mainStore";
import { TButton } from "@variantjs/vue";
import BookmarkIcon from "vue-material-design-icons/Bookmark.vue";
import BookmarkOutlineIcon from "vue-material-design-icons/BookmarkOutline.vue";
import ThumbUpIcon from "vue-material-design-icons/ThumbUp.vue";
import ThumbUpOutlineIcon from "vue-material-design-icons/ThumbUpOutline.vue";
import HandCoinIcon from "vue-material-design-icons/HandCoin.vue";
import ShareIcon from "vue-material-design-icons/Share.vue";
import DeleteIcon from "vue-material-design-icons/Delete.vue";
import EyeIcon from "vue-material-design-icons/Eye.vue";
import SpeedometerIcon from "vue-material-design-icons/Speedometer.vue";
import PostComment from "./PostComment.vue";
import CommentEditor from "./CommentEditor.vue";
import VerticalFactCheck from "./VerticalFactCheck.vue";
import SharedPost from "./SharedPost.vue";
import { Section } from "../../models";
import VRuntimeTemplate from "vue3-runtime-template";
import { getFormattedRelativeTimestamp } from "../../utils";

export default defineComponent({
  name: "PostPreview",
  props: {
    post: {
      type: Object as PropType<Post>,
      required: true,
    },
    comments: {
      type: Array as PropType<Comment[]>,
      default: () => [],
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    canShare: {
      type: Boolean,
      default: true,
    },
    disableComment: {
      type: Boolean,
      default: false,
    },
  },
  async created() {
    if (this.post.my_fact_check_vote) {
      this.factCheckVote = this.post.my_fact_check_vote;
    }
    if (this.post.section) {
      this.postSection = await this.mainStore.getSectionById(this.post.section);
    }
    if (this.post.section_parent) {
      this.postSectionParent = await this.mainStore.getSectionById(
        this.post.section_parent
      );
    }
  },
  mounted() {
    if (this.post.shared) {
      return;
    }
    this.observer = new IntersectionObserver(
      (entries) => {
        // If the component is in view
        if (entries[0].isIntersecting && this.observer) {
          // Now we fetch comments
          this.mainStore.getPostComments(this.post.id);
          try {
            // Once we've fetched the comments, we don't need the observer anymore
            this.observer.unobserve(this.$refs.observer as Element);
          } catch {
            // too bad
          }
        }
      },
      {
        // Adjust the margins to make the observer trigger when the element is close to being in view.
        rootMargin: "100px",
      }
    );
    // Start observing the component
    this.observer.observe(this.$refs.observer as Element);
  },
  data() {
    return {
      factCheckVote: 50,
      postSection: null as null | Section,
      postSectionParent: null as null | Section,
      observer: null as null | IntersectionObserver,
      showAll: false,
      showAllComments: false,
      shownCommentsTab: "fact_check" as "regular" | "fact_check",
    };
  },
  methods: {
    onGiveCoin() {
      this.$emit("giveCoin", this.post);
    },
    onShowAllClick() {
      this.showAll = true;
    },
    injectButtonIntoLastParagraph(html: string, buttonHtml: string) {
      const parser = new DOMParser();
      const doc = parser.parseFromString(html, "text/html");
      const paragraphs = doc.querySelectorAll("p");
      const lastParagraph = paragraphs[paragraphs.length - 1];
      lastParagraph.innerHTML += buttonHtml;
      const serializer = new XMLSerializer();
      return serializer.serializeToString(doc.body);
    },
  },
  watch: {
    regularCommentsCount: {
      immediate: true,
      handler(count) {
        if (count > 0 && this.factCheckComments.length === 0) {
          this.shownCommentsTab = "regular";
        }
      },
    },
  },
  computed: {
    ...mapStores(useMainStore),
    regularCommentsCount() {
      return this.regularComments.length;
    },
    regularComments() {
      return this.comments.filter((c) => c.comment_type === "regular");
    },
    factCheckComments() {
      return this.comments.filter((c) => c.comment_type === "fact_check");
    },
    shownRegularComments() {
      // regular comments
      if (this.showAllComments) {
        return this.regularComments;
      }
      return [...this.regularComments].slice(0, 3);
    },
    postAuthorProfileRoute() {
      return {
        name: "UserProfile",
        params: {
          userId: this.post.author.id,
        },
      };
    },
    isPostOwner() {
      return this.post.author.id == this.mainStore.user?.id;
    },
    shownPostContent() {
      if (this.showAll) {
        return {
          text: this.$sanitize(this.post.content),
          cutoffApplied: false,
        };
      }
      const parser = new DOMParser();
      const doc = parser.parseFromString(this.post.content, "text/html");

      let text = "";
      let length = 0;
      let cutoff = false;
      let tags: string[] = []; // Stores the currently open tags

      const selfClosingTags = [
        "br",
        "img",
        "input",
        "area",
        "base",
        "col",
        "command",
        "embed",
        "hr",
        "keygen",
        "link",
        "meta",
        "param",
        "source",
        "track",
        "wbr",
      ];

      const traverse = (node: any) => {
        for (let child of node.childNodes) {
          if (cutoff) return;
          if (child.nodeType === Node.TEXT_NODE) {
            length += child.textContent.length;
            if (length > 500) {
              text +=
                child.textContent.slice(
                  0,
                  500 - (length - child.textContent.length)
                ) + "...";
              cutoff = true;
              // Close any open tags
              while (tags.length > 0) {
                text += `</${tags.pop()}>`;
              }
            } else {
              text += child.textContent;
            }
          } else if (child.nodeType === Node.ELEMENT_NODE) {
            if (selfClosingTags.includes(child.tagName.toLowerCase())) {
              text += child.outerHTML;
            } else {
              let html = `<${child.tagName.toLowerCase()}>`;
              text += html;
              tags.push(child.tagName.toLowerCase()); // Add the tag to the stack
              traverse(child);
              if (!cutoff) {
                html = `</${tags.pop()}>`; // Close the tag if not cutoff
                text += html;
              }
            }
          }
        }
      };

      traverse(doc.body);
      return {
        text: this.$sanitize(text),
        cutoffApplied: cutoff,
      };
    },
    postContentTemplate() {
      const { text, cutoffApplied } = this.shownPostContent;
      const buttonHtml = cutoffApplied
        ? `<TButton style="padding-top: 1px !important; padding-bottom: 1px !important; z-index: 2" :variant="'icon'" @click="onShowAllClick()" class="relative font-medium show-all-button">Mostra tutto</TButton>`
        : "";

      return this.injectButtonIntoLastParagraph(text, buttonHtml).replace(
        /tbutton/g,
        "TButton"
      );
    },
    formattedTimestamp() {
      return getFormattedRelativeTimestamp(this.post.created_at);
      // return getFormattedTimestamp(this.post.created_at, false, true);
    },
    // formattedTimestampReduced() {
    //   return getFormattedTimestamp(this.post.created_at, true, true);
    // },
  },
  components: {
    UserAvatar,
    TButton,
    BookmarkIcon,
    BookmarkOutlineIcon,
    ThumbUpIcon,
    ThumbUpOutlineIcon,
    HandCoinIcon,
    PostComment,
    CommentEditor,
    ShareIcon,
    SharedPost,
    VerticalFactCheck,
    EyeIcon,
    SpeedometerIcon,
    VideoPlayer,
    DeleteIcon,
    VRuntimeTemplate,
  },
});
