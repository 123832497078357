import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import BaseHome from "../views/Home/BaseHome.vue";
import UserProfile from "../views/Profile/UserProfile.vue";
import Login from "../views/Login.vue";
import Register from "../views/Register.vue";
import ProfileSettings from "../views/Profile/ProfileSettings.vue";
import PostDetail from "../components/Post/PostDetail.vue";
const routes: Array<RouteRecordRaw> = [
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      disableNavBar: true,
    },
  },
  {
    path: "/register",
    name: "Register",
    component: Register,
    meta: {
      disableNavBar: true,
    },
  },
  {
    path: "/me",
    name: "ProfileSettings",
    component: ProfileSettings,
  },
  {
    path: "/guidelines",
    name: "Guidelines",
    component: () => import("../views/GuideLines.vue"),
  },
  // Home
  {
    path: "/",
    redirect: { name: "Home", params: { postType: "dissemination" } },
    // TODO logged in guard
  },
  {
    path: "/section/:sectionId",
    name: "SectionHome",
    component: BaseHome,
    props: true,
  },
  {
    path: "/:postType?",
    name: "Home",
    component: BaseHome,
    props: true,
  },
  // User profile
  {
    path: "/user/:userId/:postType?",
    beforeEnter: (to, _from, next) => {
      if (!to.params.postType) {
        to.params.postType = "mixed";
      }
      next();
    },
    name: "UserProfile",
    component: UserProfile,
    props: true,
  },
  {
    path: "/user/:userId/section/:sectionId",
    name: "UserProfileSection",
    component: UserProfile,
    props: true,
  },
  // Posts
  {
    path: "/post/:postId",
    name: "PostDetail",
    component: PostDetail,
    props: true,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
