
import { v4 as uuid4 } from "uuid";
import { defineComponent, PropType } from "@vue/runtime-core";
import { MDCSnackbar } from "@material/snackbar";
import { mapStores } from "pinia";
import { useMainStore } from "../../stores/mainStore";

export default defineComponent({
  name: "SnackBar",
  props: {},
  data() {
    return {
      baseUuid: uuid4(),
      snackbar: null as MDCSnackbar | null,
    };
  },
  watch: {
    message(newVal) {
      if (newVal) {
        this.openFor(newVal, 5000);
      }
    },
  },
  mounted() {
    const snackbar = new MDCSnackbar(
      document.querySelector("#" + this.snackbarId) as Element
    );
    this.snackbar = snackbar;
  },
  methods: {
    openFor(message: string, timeoutMs: number) {
      if (this.snackbar) {
        this.snackbar.timeoutMs = timeoutMs;
        this.snackbar.labelText = message;
        this.snackbar.open();
      }
    },
  },
  computed: {
    ...mapStores(useMainStore),
    snackbarId(): string {
      return "a" + this.baseUuid + "-mdc-snackbar";
    },
    message(): string {
      return this.mainStore.snackbarMessage;
    },
  },
});
