
import { defineComponent, PropType } from "@vue/runtime-core";
import { Comment } from "../../models/interfaces";
import UserAvatar from "../UserAvatar.vue";
export default defineComponent({
  name: "PostComment",
  props: {
    comment: {
      type: Object as PropType<Comment>,
      required: true,
    },
  },
  methods: {},
  computed: {
    formattedTimestamp() {
      return new Date(this.comment.created_at).toLocaleString("it", {
        dateStyle: "long",
        timeStyle: "short",
        // dayPeriod: "short",
      });
    },
    commentAuthorProfileRoute() {
      return {
        name: "UserProfile",
        params: {
          userId: this.comment.user.id,
        },
      };
    },
  },
  components: { UserAvatar },
});
