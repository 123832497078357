
import { defineComponent, PropType } from "@vue/runtime-core";
import { Section } from "@/models/interfaces";
import SectionListItem from "@/components/Sections/SectionListItem.vue";
export default defineComponent({
  name: "SectionList",
  components: { SectionListItem },
  props: {
    sections: {
      type: Array as PropType<Section[]>,
      required: true,
    },
  },
  methods: {
    getSubSections(parentId: string) {
      return this.sections.filter((s) => s.parent == parentId);
    },
  },
  computed: {
    topLevelSections() {
      return this.sections.filter((s) => s.parent === null);
    },
    processedSections() {
      // if we're at top level, only show top-level sections, otherwise show all
      return this.$route.params.sectionId
        ? this.sections
        : this.topLevelSections;
    },
  },
});
