import { Post, Comment } from "../models/interfaces";

export const mockDisseminationPosts: Post[] = [
  {
    id: "1",
    author: {
      id: "1",
      full_name: "Mario Rossi",
      profile_picture: "https://picsum.photos/200",
      is_followed: true,
    },
    content:
      "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Corrupti in, ipsa magni deleniti odit pariatur culpa soluta, maxime aliquam distinctio voluptate vitae nulla! Deleniti dicta cum amet commodi esse explicabo.",
    created_at: "2020-01-01T00:00:00.000Z",
    post_type: "dissemination",
    fact_check_score: 0.8,
    my_fact_check_vote: 1,
    saved: true,
    section: "1",
  },
  {
    id: "2",
    author: {
      id: "1",
      full_name: "Mario Rossi",
      profile_picture: "https://picsum.photos/200",
      is_followed: true,
    },
    content:
      "Post 2 abc lorem ipsum. Lorem ipsum dolor sit, amet consectetur adipisicing elit. Corrupti in, ipsa magni deleniti odit pariatur culpa soluta, maxime aliquam distinctio voluptate vitae nulla! ",
    created_at: "2020-01-01T00:00:00.000Z",
    post_type: "dissemination",
    fact_check_score: 0.4,
    my_fact_check_vote: 1,
    saved: true,
    section: "1",
  },
];

export const mockPersonalPosts: Post[] = [
  {
    id: "20",
    author: {
      id: "1",
      full_name: "Mario Rossi",
      profile_picture: "https://picsum.photos/200",
      is_followed: true,
    },
    content:
      "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Corrupti in, ipsa magni deleniti odit pariatur culpa soluta, maxime aliquam distinctio voluptate vitae nulla! Deleniti dicta cum amet commodi esse explicabo. Lorem ipsum dolor sit, amet consectetur adipisicing elit. Corrupti in, ipsa magni deleniti odit pariatur culpa soluta, maxime aliquam distinctio voluptate vitae nulla! Deleniti dicta cum amet commodi esse explicabo.",
    created_at: "2020-01-01T00:00:00.000Z",
    post_type: "personal",
    fact_check_score: 85,
    my_fact_check_vote: 90,
    saved: true,
    section: "5",
    liked: true,
  },
  {
    id: "2",
    author: {
      id: "1",
      full_name: "Mario Rossi",
      profile_picture: "https://picsum.photos/200",
      is_followed: true,
    },
    content:
      "Post 2 abc lorem ipsum. Lorem ipsum dolor sit, amet consectetur adipisicing elit. Corrupti in, ipsa magni deleniti odit pariatur culpa soluta, maxime aliquam distinctio voluptate vitae nulla! ",
    created_at: "2020-01-01T00:00:00.000Z",
    post_type: "personal",
    fact_check_score: 65,
    my_fact_check_vote: 30,
    saved: true,
    section: "1",
  },
];

export const mockPostComments: Record<string, Comment[]> = {
  "20": [
    {
      id: "1",
      user: {
        id: "1",
        full_name: "Mario Rossi",
        profile_picture: "https://picsum.photos/200",
        is_followed: true,
      },
      created_at: "2020-01-01T00:00:00.000Z",
      content: "Lorem ipsum dolor sit, amet consectetur adipisicing elit.",
      comment_type: "regular",
    },
    {
      id: "2",
      user: {
        id: "1",
        full_name: "Mario Rossi",
        profile_picture: "https://picsum.photos/200",
        is_followed: true,
      },
      created_at: "2020-01-01T00:00:00.000Z",
      content:
        "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Corrupti in, ipsa magni deleniti odit pariatur.",
      comment_type: "regular",
    },
  ],
};
