import axios from "axios";
import { Video } from "./models";
import { useMainStore } from "./stores/mainStore";
import router from "./router";
import moment from "moment";

/* eslint-disable @typescript-eslint/no-explicit-any */
function setLoadingState(state: boolean) {
  // TODO implement;
  state;
  return;
}

export interface SelectableOption<T> {
  label: string;
  value: T;
  icon?: string;
}

export const wrapWithLoading = async (
  fn: () => any,
  errorCallback?: (e: any) => void
) => {
  setLoadingState(true);
  try {
    await fn();
    setLoadingState(false);
  } catch (e) {
    errorCallback?.(e);
  } finally {
    setLoadingState(false);
  }
};

export const showSuccessNotification = (message?: string) => {
  console.log("SUCCESS", message);
  useMainStore().snackbarMessage =
    message || "Operazione effettuata con successo";
};

export function setErrorNotification(message: string) {
  // TODO implement;
  console.error(message);
  return;
}

export const uploadVideo = async (videoFile: Blob): Promise<Video> => {
  const data = new FormData();
  data.append("file", videoFile);

  const response = await axios.post("/videos/", data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

  return response.data;
};

export const clickOutsideDirective = {
  beforeMount: (
    el: {
      clickOutsideEvent: {
        (event: any): void;
        (this: Document, ev: MouseEvent): any;
      };
      contains: (arg0: any) => any;
    },
    binding: { value: () => void }
  ) => {
    el.clickOutsideEvent = (event: any) => {
      // check that click was outside the element and its children
      if (!(el == event.target || el.contains(event.target))) {
        binding.value?.();
      }
    };
    document.addEventListener("click", el.clickOutsideEvent);
  },
  unmounted: (el: {
    clickOutsideEvent: (this: Document, ev: MouseEvent) => any;
  }) => {
    document.removeEventListener("click", el.clickOutsideEvent);
  },
};

export const stripHtmlFromString = (html: string) => {
  const tmp = document.createElement("DIV");
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText || "";
};

declare const Buffer: any;

export const arraybufferToBase64 = (data: ArrayBuffer): string => {
  return Buffer.from(data, "binary").toString("base64");
};

export const logOut = (showMessage = true, redirect = ""): void => {
  const mainStore = useMainStore();
  mainStore.resetToken();

  router.push({
    name: "Login",
    ...(redirect ? { query: { redirect } } : {}),
  });
  if (showMessage) {
    showSuccessNotification("Logout effettuato con successo");
  }
};

export const getFormattedTimestamp = (
  timestamp: string,
  dateOnly = false,
  reduced = false
): string => {
  return moment(timestamp)
    .locale("it")
    .format(
      reduced
        ? "DD MMM YYYY" + (dateOnly ? "" : ", HH:mm")
        : "DD MMMM YYYY" + (dateOnly ? "" : `, [alle] HH:mm`)
    );
};

export const getFormattedRelativeTimestamp = (
  timestamp: string
  // dateOnly = false,
  // reduced = false
): string => {
  moment.updateLocale("it", {
    relativeTime: {
      past: function (val) {
        return val + (val === "ieri" || val === "adesso" ? "" : " fa");
      },
      s: "adesso",
      m: "1 minuto",
      mm: "%d minuti",
      h: "un'ora",
      hh: "%d ore",
      d: "ieri",
      dd: "%d giorni",
      M: "un mese",
      MM: "%d mesi",
      y: "un anno",
      yy: "%d anni",
    },
  });

  return moment(timestamp).locale("it").fromNow();
  // .format(
  //   reduced
  //     ? "DD MMM YYYY" + (dateOnly ? "" : ", HH:mm")
  //     : "DD MMMM YYYY" + (dateOnly ? "" : `, [alle] HH:mm`)
  // );
};
