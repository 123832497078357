
import { defineComponent, PropType } from "@vue/runtime-core";
import { mapStores } from "pinia";
import { useMainStore } from "../../stores/mainStore";
import { TCard } from "@variantjs/vue";
import { TButton } from "@variantjs/vue";
import VideoIcon from "vue-material-design-icons/Video.vue";
import PlayIcon from "vue-material-design-icons/Play.vue";
import CloudClockOutlineIcon from "vue-material-design-icons/CloudClockOutline.vue";
import CloseIcon from "vue-material-design-icons/Close.vue";
import LoadingSpinner from "../ui/LoadingSpinner.vue";
// import { VideoPlayer as VueVideoPlayer } from "@videojs-player/vue";
// import "video.js/dist/video-js.css";
import axios from "axios";

export default defineComponent({
  name: "VideoPlayer",
  props: {
    thumbnail: {
      type: String,
      required: false,
    },
    videoId: {
      type: String,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: false,
    },
    canRemove: {
      type: Boolean,
      default: false,
    },
    status: {
      type: String as PropType<"done" | "processing" | "failed">,
      required: true,
    },
  },
  mounted() {
    this.observer = new IntersectionObserver(
      (entries) => {
        // If the component is in view
        if (entries[0].isIntersecting && this.observer) {
          // Now we download the video
          this.downloadVideo();
          // Once we've downloaded the video, we don't need the observer anymore
          try {
            // Once we've fetched the comments, we don't need the observer anymore
            this.observer.unobserve(this.$refs.observer as Element);
          } catch {
            // too bad
          }
        }
      },
      {
        // Adjust the margins to make the observer trigger when the element is close to being in view.
        rootMargin: "100px",
      }
    );
    // Start observing the component
    this.observer.observe(this.$refs.observer as Element);
  },
  data() {
    return {
      downloading: false,
      videoSrc: "",
      arrayBufferSource: null as null | ArrayBuffer,
      showPlayer: false,
      observer: null as null | IntersectionObserver,
    };
  },
  methods: {
    async downloadVideo() {
      return;
      // if (this.status !== "done") {
      //   return;
      // }
      // this.downloading = true;
      // try {
      //   this.arrayBufferSource = await this.mainStore.getVideoById(
      //     this.videoId
      //   );
      //   this.videoSrc = (window.URL || window.webkitURL).createObjectURL(
      //     new Blob([this.arrayBufferSource as ArrayBuffer])
      //   );
      // } catch (e) {
      //   console.error(e);
      // } finally {
      //   this.downloading = false;
      // }
    },
    onPlay() {
      this.showPlayer = true;
    },
    onClose() {
      this.showPlayer = false;
    },
  },
  computed: {
    ...mapStores(useMainStore),
    base64Thumbnail() {
      if (!this.thumbnail) {
        return "";
      }
      return `data:image/png;base64,${this.thumbnail}`;
    },
    videoUrl() {
      // return axios.defaults.baseURL + `/download/${this.videoId}/#.mp4`;
      return axios.defaults.baseURL + `/videos/${this.videoId}/`;
    },
  },
  components: {
    TCard,
    VideoIcon,
    PlayIcon,
    CloseIcon,
    TButton,
    LoadingSpinner,
    // VueVideoPlayer,
    CloudClockOutlineIcon,
  },
});
