
import { defineComponent, PropType } from "@vue/runtime-core";
import { PostSource } from "@/models";
import { TButton, TInput } from "@variantjs/vue";
import PlusIcon from "vue-material-design-icons/Plus.vue";
import CloseIcon from "vue-material-design-icons/Close.vue";

export default defineComponent({
  name: "SourceList",
  props: {
    modelValue: {
      type: Array as PropType<PostSource[]>,
      required: true,
    },
  },
  data() {
    return {
      defaultSourceOptions: [
        {
          text: "Tipo di fonte",
          value: "",
          disabled: true,
        },
        {
          text: "Studio in vitro",
          value: "Studio in vitro",
        },
        {
          text: "Doppio cieco",
          value: "Doppio cieco",
        },
        {
          text: "Cieco",
          value: "Cieco",
        },
        {
          text: "Altro",
          value: "Altro",
        },
      ],
      otherType: "",
    };
  },
  methods: {
    onAddSource() {
      this.$emit("update:modelValue", [
        ...this.modelValue,
        {
          source_type: "",
          description: "",
        },
      ]);
    },
    onEditSource(index: number, source: PostSource) {
      const newSources = [...this.modelValue];
      // const updatedSource = { ...source };

      // console.log({ updatedSource });

      // if (source.source_type === "Altro") {
      //   updatedSource.source_type = this.otherType;
      // }

      newSources[index] = source;
      this.$emit("update:modelValue", newSources);
    },
    onDeleteSource(index: number) {
      const newSources = [...this.modelValue];
      newSources.splice(index, 1);
      this.$emit("update:modelValue", newSources);
    },
  },
  computed: {},
  components: { TInput, TButton, PlusIcon, CloseIcon },
});
