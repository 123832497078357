
import { TButton } from "@variantjs/vue";
import CogIcon from "vue-material-design-icons/Cog.vue";
import LogoutIcon from "vue-material-design-icons/Logout.vue";
import MenuIcon from "vue-material-design-icons/Menu.vue";
import DropdownMenu from "./ui/DropdownMenu.vue";
import UserAvatar from "@/components/UserAvatar.vue";
import { defineComponent, PropType } from "@vue/runtime-core";
import { mapStores } from "pinia";
import SearchBar from "./ui/SearchBar.vue";
import { useMainStore } from "../stores/mainStore";
import { logOut } from "../utils";
export default defineComponent({
  name: "NavBar",
  props: {},
  methods: {
    logOut,
  },
  data() {
    return {
      userCardExpanded: false,
    };
  },
  computed: {
    ...mapStores(useMainStore),
    avatarUrl(): string {
      return (this.mainStore.user?.profile_picture as string) ?? "";
    },
  },
  components: {
    UserAvatar,
    SearchBar,
    DropdownMenu,
    CogIcon,
    LogoutIcon,
    TButton,
    MenuIcon,
  },
});
