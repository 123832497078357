import { defineStore } from "pinia";
import {
  Post,
  PostType,
  Section,
  Comment,
  User,
  PreviewUser,
} from "../models/interfaces";
import {
  MOCK_SERVICE_ACTIVE,
  PostFilter,
  SectionFilter,
  formatDate,
} from "../const";
import {
  mockDisseminationPosts,
  mockPersonalPosts,
  mockPostComments,
} from "../mock/posts";
import { mockSections } from "../mock/sections";
import axios from "axios";
import { SortOption } from "../components/Sections/SectionSorter.vue";

export const useMainStore = defineStore("main", {
  state: () => ({
    posts: [] as Post[],
    sections: [] as Section[],
    postComments: {} as Record<string, Comment[]>,
    user: null as User | null,
    token: "",
    snackbarMessage: "",
    loadingPosts: false,
    loadingSections: false,
  }),
  getters: {
    userId: (state) => state.user?.id,
    postById: (state) => (id: string) => state.posts.find((p) => p.id == id),
    topLevelSections: (state) => state.sections.filter((s) => !s.parent),
  },
  actions: {
    init() {
      const token = localStorage.getItem("token");
      const user = localStorage.getItem("user");
      if (token) {
        this.token = token;
        axios.defaults.headers.common["Authorization"] = "Token " + this.token;
      }
      if (user) {
        this.user = JSON.parse(user);
      }
    },
    resetToken() {
      this.token = "";
      this.user = null;
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      axios.defaults.headers.common["Authorization"] = "";
    },
    async login(username: string, password: string) {
      axios.defaults.headers.common["Authorization"] = "";
      localStorage.removeItem("token");
      const formData = {
        username,
        password,
      };
      const response = await axios.post("/api/v1/token/login/", formData);

      const token = response.data.auth_token;
      this.token = token;

      axios.defaults.headers.common["Authorization"] = "Token " + token;
      localStorage.setItem("token", token);

      const user: User = (await axios.get("/api/v1/users/me/")).data;

      this.user = user;
      localStorage.setItem("user", JSON.stringify(user));

      return user;
    },
    async getMe() {
      const user: User = (await axios.get("/api/v1/users/me/")).data;
      this.user = user;
      localStorage.setItem("user", JSON.stringify(user));
      return user;
    },
    async updateMe(user: Partial<User>) {
      if (user.profile_picture instanceof File) {
        const formData = new FormData();
        formData.append("profile_picture", user.profile_picture);
        const response = await axios.patch("/api/v1/users/me/", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        this.user = response.data;
        localStorage.setItem("user", JSON.stringify(response.data));
        return response.data;
      }
      const updatedUser: User = (await axios.patch("/api/v1/users/me/", user))
        .data;
      this.user = updatedUser;
      localStorage.setItem("user", JSON.stringify(updatedUser));
      return updatedUser;
    },
    async getUserProfilePicture(userId: string): Promise<ArrayBuffer> {
      const response = await axios.get(`/users/${userId}/profile_picture/`, {
        responseType: "arraybuffer",
      });
      return response.data;
    },
    async registerUser(
      username: string,
      email: string,
      password: string,
      rePassword: string,
      birthDate: Date
    ) {
      const formData = {
        username,
        email,
        password,
        re_password: rePassword,
        // only send the date, not the time
        birth_date: formatDate(birthDate),
      };
      const response = await axios.post("/api/v1/users/", formData);

      return response.data;
    },
    async setSectionSorting(sortType: SortOption, customOrdering?: string[]) {
      const response = await axios.post("/sections/sort_preference/", {
        sort_type: sortType,
        custom_ordering: customOrdering,
      });
      return response.data;
    },
    async getSectionSorting(): Promise<{
      sort_type: SortOption;
      custom_ordering?: string[];
    }> {
      const response = await axios.get("/sections/sort_preference/");
      return response.data;
    },
    async createPost(post: Post, videoIds: string[] = []) {
      const newPost = await axios.post("/posts/", {
        ...post,
        video_ids: videoIds,
      });
      this.posts.unshift(newPost.data);
    },
    async updatePost(post: Post) {
      const updatedPost = await axios.put(`/posts/${post.id}/`, post);
      const index = this.posts.findIndex((p) => p.id == post.id);
      this.posts[index] = updatedPost.data;
    },
    async factCheckPost(postId: string, value: number) {
      const updatedPost = await axios.post(`/posts/${postId}/fact_check/`, {
        value,
      });
      const index = this.posts.findIndex((p) => p.id == postId);
      this.posts[index] = updatedPost.data;
    },
    async likePost(postId: string, remove: boolean) {
      const updatedPost = await axios[remove ? "delete" : "post"](
        `/posts/${postId}/like/`
      );
      const index = this.posts.findIndex((p) => p.id == postId);
      this.posts[index] = updatedPost.data;
    },
    async savePost(postId: string, remove: boolean) {
      const updatedPost = await axios[remove ? "delete" : "post"](
        `/posts/${postId}/save/`
      );
      const index = this.posts.findIndex((p) => p.id == postId);
      this.posts[index] = updatedPost.data;
    },
    async deletePost(postId: string) {
      await axios.delete(`/posts/${postId}/`);
      const index = this.posts.findIndex((p) => p.id == postId);
      this.posts.splice(index, 1);
    },
    async followUser(userId: string, remove: boolean): Promise<User> {
      const updatedUser = await axios[remove ? "delete" : "put"](
        `/users/${userId}/followers/`
      );
      return updatedUser.data;
    },
    async createComment(postId: string, comment: Comment) {
      const newComment = await axios.post(
        `/posts/${postId}/comments/`,
        comment
      );
      this.postComments[postId].unshift(newComment.data);
    },
    async getPostComments(postId: string) {
      if (MOCK_SERVICE_ACTIVE) {
        if (this.postComments[postId]) {
          return this.postComments[postId];
        }
        this.postComments[postId] = mockPostComments[postId];
      } else {
        const comments = await axios.get(`/posts/${postId}/comments/`);
        this.postComments[postId] = comments.data;
      }
    },
    async getPosts(filters?: PostFilter, page?: number) {
      if (MOCK_SERVICE_ACTIVE) {
        this.posts =
          filters?.post_type === "dissemination"
            ? mockDisseminationPosts
            : filters?.post_type === "personal"
            ? mockPersonalPosts
            : [...mockDisseminationPosts, ...mockPersonalPosts];
        this.posts = this.posts.filter((p) => {
          if (filters?.section) {
            return (
              p.section == filters.section ||
              mockSections.find((s) => s.parent == filters.section)?.id ==
                p.section
            );
          }
          return true;
        });
      } else {
        const params = new URLSearchParams();
        if (filters) {
          if (filters.post_type) {
            params.append("post_type", filters.post_type);
          }
          if (filters.author !== undefined) {
            params.append("author", filters.author);
          }
          if (filters.section !== undefined) {
            params.append("section", filters.section);
          }
          if (filters.saved_by !== undefined) {
            params.append("saved_by", filters.saved_by);
          }
        }
        const url = `/posts/?${params.toString()}${
          page ? `&page=${page}` : "&paginate=false"
        }`;
        const posts = await axios.get(url);
        if (page) {
          if (page > 1) {
            this.posts.push(...posts.data.results);
          } else {
            this.posts = posts.data.results;
          }
          return posts.data.next !== null;
        }
        this.posts = posts.data;
        return false;
      }
    },
    async getPostById(id: string) {
      if (MOCK_SERVICE_ACTIVE) {
        return mockDisseminationPosts.find((post) => post.id == id) as Post;
      }
      const post = await axios.get(`/posts/${id}/`);
      // insert the post in the store or update it if it already exists
      const index = this.posts.findIndex((p) => p.id == id);
      if (index == -1) {
        this.posts.unshift(post.data);
      } else {
        this.posts[index] = post.data;
      }
      return post.data;
    },
    async getSections(filters?: SectionFilter) {
      if (MOCK_SERVICE_ACTIVE) {
        this.sections = mockSections;
        if (filters && filters.parent !== undefined) {
          this.sections = this.sections.filter(
            (s) => s.parent == filters.parent
          );
        }
      } else {
        const params = new URLSearchParams();
        if (filters) {
          if (filters.parent !== undefined && filters.parent !== null) {
            params.append("parent", filters.parent);
          }
          if (filters.relevant_for !== undefined) {
            params.append("relevant_for", filters.relevant_for);
          }
        }

        // Append query parameters to the URL
        const url = `/sections/?${params.toString()}`;
        const sections = await axios.get(url);
        this.sections = sections.data;
      }
    },
    async getSectionById(id: string) {
      if (MOCK_SERVICE_ACTIVE) {
        return mockSections.find((section) => section.id == id) as Section;
      } else {
        const localSection = this.sections.find((s) => s.id == id);
        if (localSection) {
          return localSection;
        }
        const { data: section } = await axios.get(`/sections/${id}/`);
        // this.sections.unshift(section);
        return section;
      }
    },
    async getUserById(userId: string): Promise<User> {
      if (MOCK_SERVICE_ACTIVE) {
        return {
          id: "1",
          full_name: "John Doe",
          followers_count: 10,
          profile_picture: "https://i.pravatar.cc/300",
          is_followed: true,
          user_type: "disseminator",
          wallet_balance: 100,
          bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec auctor, nisl eget ultricies aliquam, nunc nisl ultricies nunc, vitae aliquam nisl nunc eget nisl.",
          birth_date: "1990-01-01",
        };
      } else {
        const user = await axios.get(`/users/${userId}/`);
        return user.data;
      }
    },
    async getUserFollowersByUserId(userId: string): Promise<PreviewUser[]> {
      const users = await axios.get(`/users/${userId}/followers/`);
      return users.data;
    },
    async getUserFollowedByUserId(userId: string): Promise<PreviewUser[]> {
      const users = await axios.get(`/users/${userId}/followed/`);
      return users.data;
    },
    async getVideoById(videoId: string): Promise<ArrayBuffer> {
      const video = await axios.get(`/videos/${videoId}/`, {
        responseType: "arraybuffer",
      });
      return video.data;
    },
  },
});
