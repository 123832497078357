
import SnackBar from "./components/ui/SnackBar.vue";
import { defineComponent } from "@vue/runtime-core";
import NavBar from "./components/NavBar.vue";
import { useMainStore } from "./stores/mainStore";
import { useRoute } from "vue-router";
import { computed, ref } from "vue";
import MobileSidebar from "@/components/ui/MobileSidebar.vue";
import SectionList from "./components/Sections/SectionList.vue";
import { Slide } from "vue3-burger-menu";
import { logOut } from "./utils";
import UserAvatar from "./components/UserAvatar.vue";
import CogIcon from "vue-material-design-icons/Cog.vue";
import LogoutIcon from "vue-material-design-icons/Logout.vue";
export default defineComponent({
  name: "App",
  props: {},
  methods: {},
  setup() {
    const mainStore = useMainStore();
    mainStore.init();

    const mobileSidebarExpanded = ref(false);

    // show the navbar only if the route meta doesn't disable it
    const showNavBar = computed(() => {
      const route = useRoute();
      return !route.meta.disableNavBar;
    });

    const shownSections = computed(() => {
      return mainStore.sections;
    });

    return {
      showNavBar,
      mobileSidebarExpanded,
      shownSections,
      mainStore,
      logOut,
    };
  },
  components: {
    NavBar,
    SnackBar,
    Slide,
    SectionList,
    UserAvatar,
    CogIcon,
    LogoutIcon,
  },
});
