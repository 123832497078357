
import { TTextarea } from "@variantjs/vue";
import { defineComponent, PropType } from "@vue/runtime-core";
import AvatarSelector from "../../components/ui/AvatarSelector.vue";
import { mapStores } from "pinia";
import { useMainStore } from "../../stores/mainStore";
import EditIcon from "vue-material-design-icons/Pencil.vue";
import DoneIcon from "vue-material-design-icons/Check.vue";
import CloseIcon from "vue-material-design-icons/Close.vue";
import { TButton } from "@variantjs/vue";
import { showSuccessNotification } from "../../utils";
import PostList from "../../components/PostList.vue";

export default defineComponent({
  name: "ProfileSettings",
  props: {},
  async created() {
    await this.mainStore.getMe();
    this.dirtyBio = this.mainStore.user?.bio ?? "";
    try {
      this.mainStore.loadingPosts = true;
      await this.mainStore.getPosts({ saved_by: this.mainStore.user?.id });
    } finally {
      this.mainStore.loadingPosts = false;
    }
  },
  methods: {
    async onUpdateBio() {
      this.savingBio = true;
      try {
        await this.mainStore.updateMe({ bio: this.dirtyBio });
        showSuccessNotification("Bio aggiornata");
      } finally {
        this.savingBio = false;
        this.editingBio = false;
      }
    },
  },
  data() {
    return {
      editingBio: false,
      dirtyBio: "",
      savingBio: false,
    };
  },
  computed: {
    ...mapStores(useMainStore),
  },
  components: {
    AvatarSelector,
    TTextarea,
    EditIcon,
    TButton,
    DoneIcon,
    CloseIcon,
    PostList,
  },
});
