import { useMainStore } from "@/stores/mainStore";
import { PostFilter } from "@/const";

export default function usePostsByType() {
  const mainStore = useMainStore();

  const fetchPosts = async (filters?: PostFilter, page?: number) => {
    mainStore.loadingPosts = true;
    try {
      const moreResults = await mainStore.getPosts(filters, page);
      return moreResults;
    } finally {
      mainStore.loadingPosts = false;
    }
  };

  return {
    fetchPosts,
  };
}
