import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "relative w-56 overflow-hidden rounded h-36",
  style: {"margin":"-16px !important"}
}
const _hoisted_2 = { class: "absolute right-0 z-50 hidden text-light filter hover:text-black drop-shadow-xl group-hover:block" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VideoIcon = _resolveComponent("VideoIcon")!
  const _component_CloseIcon = _resolveComponent("CloseIcon")!
  const _component_TButton = _resolveComponent("TButton")!
  const _component_TCard = _resolveComponent("TCard")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_TCard, { class: "transition-colors duration-200 ease-out group hover:bg-slate-100" }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", {
            style: _normalizeStyle(
            _ctx.base64Thumbnail
              ? 'background-image: url(' +
                _ctx.base64Thumbnail +
                '); background-size: cover; filter: brightness(0.8)'
              : ''
          ),
            class: "absolute top-0 left-0 flex flex-col items-center w-56 h-36"
          }, [
            (!_ctx.video.thumbnail)
              ? (_openBlock(), _createBlock(_component_VideoIcon, {
                  key: 0,
                  class: "z-10 duration-300 ease-out transform text-secondary group-hover:scale-110",
                  size: 40
                }))
              : _createCommentVNode("", true)
          ], 4),
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_TButton, {
              variant: 'icon',
              class: "bg-white bg-opacity-30",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('deleteVideo')))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_CloseIcon)
              ]),
              _: 1
            })
          ])
        ])
      ]),
      _: 1
    })
  ]))
}