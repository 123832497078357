
import { defineComponent, PropType } from "@vue/runtime-core";
export default defineComponent({
  name: "UserAvatar",
  props: {
    url: {
      type: String as PropType<string>,
      required: true,
    },
    size: {
      type: String as PropType<"sm" | "md" | "lg" | "xl">,
      default: "md",
    },
  },
  methods: {},
  computed: {
    urlWithFallback() {
      return this.url || "https://via.placeholder.com/150";
    },
    sizeStyle() {
      const sizes = {
        sm: "1.5rem",
        md: "2.5rem",
        lg: "3.5rem",
        xl: "15rem",
      };

      return {
        "min-width": sizes[this.size],
        "min-height": sizes[this.size],
        "max-width": sizes[this.size],
        "max-height": sizes[this.size],
      };
    },
  },
});
