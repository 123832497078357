import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "h-full" }
const _hoisted_2 = { class: "flex items-center space-x-2" }
const _hoisted_3 = {
  key: 0,
  class: "flex flex-col"
}
const _hoisted_4 = { class: "flex items-center" }
const _hoisted_5 = { class: "text-xl text-link" }
const _hoisted_6 = { class: "flex items-center space-x-2 text-sm text-muted" }
const _hoisted_7 = { class: "flex items-center space-x-2 text-sm text-muted" }
const _hoisted_8 = { class: "text-sm text-muted" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NavBar = _resolveComponent("NavBar")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_SnackBar = _resolveComponent("SnackBar")!
  const _component_UserAvatar = _resolveComponent("UserAvatar")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_CogIcon = _resolveComponent("CogIcon")!
  const _component_TButton = _resolveComponent("TButton")!
  const _component_LogoutIcon = _resolveComponent("LogoutIcon")!
  const _component_SectionList = _resolveComponent("SectionList")!
  const _component_Slide = _resolveComponent("Slide")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.showNavBar)
      ? (_openBlock(), _createBlock(_component_NavBar, {
          key: 0,
          onOpenMobileSidebar: _cache[0] || (_cache[0] = ($event: any) => (_ctx.mobileSidebarExpanded = !_ctx.mobileSidebarExpanded))
        }))
      : _createCommentVNode("", true),
    _createVNode(_component_router_view, { class: "" }),
    _createVNode(_component_SnackBar, { ref: "snackbarRef" }, null, 512),
    _createVNode(_component_Slide, {
      isOpen: _ctx.mobileSidebarExpanded,
      closeOnNavigation: true,
      disableOutsideClick: true
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_UserAvatar, {
            size: 'lg',
            url: _ctx.mainStore.user?.profile_picture ?? ''
          }, null, 8, ["url"]),
          (_ctx.mainStore.user)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(_component_router_link, {
                    class: "mr-2",
                    to: {
                name: 'UserProfile',
                params: {
                  userId: _ctx.mainStore.user.id,
                },
              }
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("h4", _hoisted_5, _toDisplayString(_ctx.mainStore.user.full_name), 1)
                    ]),
                    _: 1
                  }, 8, ["to"]),
                  _createVNode(_component_router_link, { to: { name: 'ProfileSettings' } }, {
                    default: _withCtx(() => [
                      _createVNode(_component_TButton, {
                        variant: 'icon',
                        class: ""
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_6, [
                            _createVNode(_component_CogIcon, { size: "22" })
                          ])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_TButton, {
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.logOut())),
                    variant: 'icon',
                    class: "ml-2"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_7, [
                        _createVNode(_component_LogoutIcon, { size: "22" })
                      ])
                    ]),
                    _: 1
                  })
                ]),
                _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.mainStore.user.email), 1)
              ]))
            : _createCommentVNode("", true)
        ]),
        _createVNode(_component_SectionList, { sections: _ctx.shownSections }, null, 8, ["sections"])
      ]),
      _: 1
    }, 8, ["isOpen"])
  ]))
}