import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex flex-col space-y-6" }
const _hoisted_2 = { class: "flex items-center space-x-2" }
const _hoisted_3 = { class: "text-base" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = { class: "p-4 bg-opacity-50 rounded-md bg-light" }
const _hoisted_6 = {
  key: 0,
  class: "flex justify-end"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UserAvatar = _resolveComponent("UserAvatar")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_PostPreview = _resolveComponent("PostPreview")!
  const _component_DeleteIcon = _resolveComponent("DeleteIcon")!
  const _component_TButton = _resolveComponent("TButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_router_link, { to: _ctx.postAuthorProfileRoute }, {
        default: _withCtx(() => [
          _createVNode(_component_UserAvatar, {
            class: "",
            size: 'sm',
            url: _ctx.post.author.profile_picture
          }, null, 8, ["url"])
        ]),
        _: 1
      }, 8, ["to"]),
      _createElementVNode("h3", _hoisted_3, [
        _createVNode(_component_router_link, {
          class: "text-link",
          to: _ctx.postAuthorProfileRoute
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.post.author.full_name), 1)
          ]),
          _: 1
        }, 8, ["to"]),
        _createTextVNode(" ha condiviso "),
        _createVNode(_component_router_link, {
          class: "font-medium text-link",
          to: _ctx.sharedPostRoute
        }, {
          default: _withCtx(() => [
            _createTextVNode("un post")
          ]),
          _: 1
        }, 8, ["to"])
      ])
    ]),
    _createElementVNode("div", {
      innerHTML: _ctx.post.content
    }, null, 8, _hoisted_4),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_PostPreview, {
        post: _ctx.post.shared,
        onFactCheck: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('factCheck', $event))),
        onShowSources: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('showSources', $event))),
        readonly: true,
        canShare: false
      }, null, 8, ["post"])
    ]),
    (_ctx.isPostOwner)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createVNode(_component_TButton, {
            class: "text-gray-600",
            variant: 'icon',
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('delete', _ctx.post)))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_DeleteIcon)
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true)
  ]))
}