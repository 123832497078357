
import { defineComponent, PropType } from "@vue/runtime-core";
import { getBlankPost } from "../../models/factories";
import TextEditor from "../ui/TextEditor.vue";
import SegmentedButtons from "../ui/SegmentedButtons.vue";
import { TButton, TCard, TRichSelect } from "@variantjs/vue";
import PublishIcon from "vue-material-design-icons/Publish.vue";
import PlusIcon from "vue-material-design-icons/Plus.vue";
import { mapStores } from "pinia";
import { useMainStore } from "../../stores/mainStore";
import VideoInput from "../ui/VideoInput.vue";
import SourceList from "./SourceList.vue";
import { Section, Video } from "../../models";
import { showSuccessNotification, uploadVideo } from "../../utils";
import VideoThumbnail from "./VideoThumbnail.vue";
import VideoPlayer from "../Post/VideoPlayer.vue";

const LOCAL_VIDEOS_KEY = "draft_post_video_ids";

export default defineComponent({
  name: "PostEditor",
  props: {
    canCreateDisseminationPost: {
      type: Boolean,
      required: true,
    },
  },
  created() {
    this.localVideos = this.getDraftVideos();
  },
  watch: {
    "post.post_type": {
      handler(newVal) {
        if (newVal === "personal") {
          this.post.section = null;
          this.post.sources = [];
        }
      },
    },
  },
  data() {
    return {
      post: getBlankPost(
        this.canCreateDisseminationPost ? "dissemination" : "personal"
      ),
      postTypeOptions: [
        {
          label: "Divulgativo",
          value: "dissemination",
        },
        {
          label: "Personale",
          value: "personal",
        },
      ],
      publishing: false,
      localVideos: [] as Video[],
      uploadingVideo: false,
    };
  },
  methods: {
    async onPublish() {
      this.publishing = true;
      try {
        await this.mainStore.createPost(
          this.post,
          this.getDraftVideos().map((v) => v.id)
        );
        this.resetLocalDraft();
        this.$emit("done");
        showSuccessNotification("Post pubblicato con successo");
      } catch (e) {
        console.error(e);
      } finally {
        this.publishing = false;
      }
    },
    async onVideoUpload(file: Blob) {
      this.uploadingVideo = true;
      try {
        const response = await uploadVideo(file);
        this.addDraftVideo(response);
      } catch (e) {
        console.error(e);
      } finally {
        this.uploadingVideo = false;
        (this.$refs.videoInput as any).value = null;
      }
    },
    addDraftVideo(video: Video) {
      const localVideos = JSON.parse(
        localStorage.getItem(LOCAL_VIDEOS_KEY) ?? "[]"
      ) as Video[];
      localVideos.push(video);
      localStorage.setItem(LOCAL_VIDEOS_KEY, JSON.stringify(localVideos));

      this.localVideos = localVideos;
    },
    deleteDraftVideo(video: Video) {
      const localVideos = JSON.parse(
        localStorage.getItem(LOCAL_VIDEOS_KEY) ?? "[]"
      ) as Video[];
      const index = localVideos.findIndex((v) => v.id === video.id);
      if (index !== -1) {
        localVideos.splice(index, 1);
      }
      localStorage.setItem(LOCAL_VIDEOS_KEY, JSON.stringify(localVideos));

      this.localVideos = localVideos;
    },
    getDraftVideos() {
      return JSON.parse(
        localStorage.getItem(LOCAL_VIDEOS_KEY) ?? "[]"
      ) as Video[];
    },
    resetLocalDraft() {
      this.post = getBlankPost(
        this.canCreateDisseminationPost ? "dissemination" : "personal"
      );
      localStorage.setItem(LOCAL_VIDEOS_KEY, JSON.stringify([]));
      this.localVideos = [];
    },
  },
  computed: {
    ...mapStores(useMainStore),
    invalidPost(): boolean {
      return (
        this.post.content.trim().length === 0 ||
        ((!this.post.section ||
          (this.post.sources ?? []).some(
            (s) => s.description.trim().length === 0
          )) &&
          this.post.post_type === "dissemination")
      );
    },
    sectionsOptions(): { value: string; text: string }[] {
      const sections = [...this.mainStore.sections].filter((s) => {
        const authorizedSections = (
          this.mainStore.user?.authorized_sections ?? []
        ).map((s) => String(s));

        return (
          authorizedSections.includes(String(s.id)) ||
          authorizedSections.includes(String(s.parent))
        );
      });

      function getSortedSections(parentId: string | null): any[] {
        // Filter sections based on the parent id
        return sections
          .filter((section) => section.parent === parentId)
          .sort((a, b) => a.name.localeCompare(b.name)) // Sort sections with the same parent
          .reduce((acc, section) => {
            // Recursively get the sorted subsections and concatenate them
            return acc.concat(section, getSortedSections(section.id));
          }, [] as Section[]);
      }

      // Start with parent id as null (root sections)
      const sortedSections = getSortedSections(null);

      // Map the sorted sections to the desired format
      return sortedSections.map((s) => ({
        value: s.id,
        text: (s.parent === null ? "" : "- ") + s.name,
      }));
    },
    sectionNames(): string[] {
      return this.mainStore.sections.map((s) => s.name);
    },
  },
  components: {
    TextEditor,
    SegmentedButtons,
    TCard,
    TButton,
    PublishIcon,
    TRichSelect,
    PlusIcon,
    VideoInput,
    SourceList,
    VideoThumbnail,
    VideoPlayer,
  },
});
