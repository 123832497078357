import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = ["id"]
const _hoisted_3 = ["rows", "value"]
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = {
  key: 0,
  class: "text-sm font-light text-danger-dark"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_quill_editor = _resolveComponent("quill-editor")!

  return (_openBlock(), _createElementBlock("div", {
    style: _normalizeStyle(_ctx.tall ? 'min-height: 30vh' : ''),
    id: _ctx.id
  }, [
    _createElementVNode("div", {
      onMouseup: _cache[5] || (_cache[5] = ($event: any) => (_ctx.endDragging())),
      class: "relative flex space-x-2",
      style: _normalizeStyle(_ctx.tall ? 'min-height: 30vh' : '')
    }, [
      _createElementVNode("div", {
        id: _ctx.resizablePanelId,
        style: _normalizeStyle(_ctx.tall ? 'min-height: 30vh' : ''),
        class: _normalizeClass(["relative z-10 h-full rounded-t-sm light-input bg-gray-50", { 'opacity-80': _ctx.disabled, 'w-full': !_ctx.showPreview }])
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(["z-10 tex2jax_ignore ql-editor-container", [
            _ctx.$slots.errors?.() ? 'ql-editor-container-error' : '',
            _ctx.tall ? 'ql-editor-tall' : '',
          ]])
        }, [
          (!_ctx.showBaseEditor && !_ctx.forceBaseEditor)
            ? (_openBlock(), _createBlock(_component_quill_editor, {
                key: 0,
                options: _ctx.editorOptions,
                value: _ctx.modelValue,
                disabled: _ctx.disabled || _ctx.internalDisabled,
                onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onEditorChange($event))),
                onReady: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onEditorReady($event)))
              }, null, 8, ["options", "value", "disabled"]))
            : (_openBlock(), _createElementBlock("textarea", {
                key: 1,
                class: "py-3.5 px-3.5 bg-transparent rounded-t-sm outline-none",
                rows: _ctx.baseEditorRows,
                onInput: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('update:modelValue', $event.target.value))),
                value: _ctx.modelValue
              }, null, 40, _hoisted_3))
        ], 2),
        _createElementVNode("label", {
          class: _normalizeClass(["absolute -z-1 top-2 left-2 origin-0 ql-floating-label", { 'fixed-label': _ctx.modelValue?.length > 0 }])
        }, [
          _renderSlot(_ctx.$slots, "default")
        ], 2)
      ], 14, _hoisted_2),
      (_ctx.showPreview)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            style: _normalizeStyle([_ctx.dividerStyle, {"width":"5px","transition-property":"background"}]),
            onMousedown: _cache[3] || (_cache[3] = ($event: any) => (_ctx.startDragging())),
            class: "absolute top-0 z-30 h-full duration-150 delay-75 bg-gray-300 bg-opacity-50 resizer hover:bg-opacity-70"
          }, null, 36))
        : _createCommentVNode("", true),
      (_ctx.showPreview)
        ? (_openBlock(), _createElementBlock("span", {
            key: 1,
            onMousedown: _cache[4] || (_cache[4] = ($event: any) => (_ctx.startDragging())),
            style: _normalizeStyle({
          ..._ctx.dividerStyle,
          transform: 'translate(-40%, -50%) rotate(90deg)',
          'font-size': '20px !important',
        }),
            class: "absolute z-30 opacity-30 resizer top-1/2 material-icons-outlined"
          }, " drag_handle ", 36))
        : _createCommentVNode("", true),
      (_ctx.showPreview)
        ? (_openBlock(), _createElementBlock("div", {
            key: 2,
            class: "relative px-8 py-2",
            style: _normalizeStyle({ width: 100 - _ctx.previewPanelWidth + '%' })
          }, [
            _createElementVNode("div", {
              style: {"white-space":"break-spaces"},
              class: "w-full",
              innerHTML: _ctx.stripHtmlFromLaTexBlocks(_ctx.modelValue)
            }, null, 8, _hoisted_4)
          ], 4))
        : _createCommentVNode("", true)
    ], 36),
    (_ctx.$slots.errors?.())
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _renderSlot(_ctx.$slots, "errors")
        ]))
      : _createCommentVNode("", true)
  ], 12, _hoisted_1))
}