
import { TRadio } from "@variantjs/vue";
import { defineComponent, PropType } from "@vue/runtime-core";
import { v4 as uuid } from "uuid";
export default defineComponent({
  name: "FactCheckVoter",
  props: {
    modelValue: {
      type: Number as PropType<number>,
      default: 0,
    },
  },
  methods: {},
  data() {
    return {
      baseUuid: uuid(),
    };
  },
  computed: {},
  components: {
    TRadio,
  },
});
