import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { variantJS } from "@variantjs/vue";
import Vue3Sanitize from "vue-3-sanitize";

import "./index.css";
import { configuration, sanitizeOptions } from "./const";
import { createPinia } from "pinia";
import axios from "axios";
import { clickOutsideDirective, logOut } from "./utils";

const pinia = createPinia();

const dev = process.env.NODE_ENV !== "production";

axios.defaults.baseURL = dev
  ? process.env.VUE_APP_AXIOS_BASE ?? "http://127.0.0.1:8000"
  : process.env.VUE_APP_AXIOS_BASE;

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (
      error?.response?.status === 401 &&
      (router.currentRoute.value.name?.toString().toLowerCase() ?? "") !==
        "login"
    ) {
      logOut(false, router.currentRoute.value.fullPath);
    } else {
      throw error;
    }
  }
);

createApp(App)
  .directive("click-outside", clickOutsideDirective)
  .use(pinia)
  .use(router)
  .use(Vue3Sanitize, sanitizeOptions)
  .use(variantJS, configuration)
  .mount("#app");
