import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives, resolveDirective as _resolveDirective } from "vue"

const _hoisted_1 = { class: "relative" }
const _hoisted_2 = { class: "flex items-center -space-x-9" }
const _hoisted_3 = {
  key: 0,
  class: "absolute z-50 w-full py-4 overflow-y-auto bg-white rounded shadow-lg",
  style: {"max-height":"28rem"}
}
const _hoisted_4 = {
  key: 0,
  class: "px-4 py-3"
}
const _hoisted_5 = {
  key: 1,
  class: "px-3 py-4"
}
const _hoisted_6 = {
  key: 0,
  class: "flex items-center space-x-2"
}
const _hoisted_7 = {
  key: 1,
  class: "flex items-center space-x-2"
}
const _hoisted_8 = { key: 2 }
const _hoisted_9 = { key: 3 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MagnifyIcon = _resolveComponent("MagnifyIcon")!
  const _component_TInput = _resolveComponent("TInput")!
  const _component_UserAvatar = _resolveComponent("UserAvatar")!
  const _component_PostOutlineIcon = _resolveComponent("PostOutlineIcon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_TButton = _resolveComponent("TButton")!
  const _directive_click_outside = _resolveDirective("click-outside")!

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_MagnifyIcon, { class: "z-10 text-gray-500" }),
      _createVNode(_component_TInput, {
        variant: 'search',
        placeholder: "Cerca contenuti",
        class: "pl-11",
        modelValue: _ctx.query,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.query) = $event)),
        onInput: _ctx.updateQuery,
        onFocus: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onFocus()))
      }, null, 8, ["modelValue", "onInput"])
    ]),
    (_ctx.showResults)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          (_ctx.loading)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, "Ricerca in corso..."))
            : _createCommentVNode("", true),
          (!_ctx.loading && _ctx.unifiedResults.length === 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, " Nessun risultato "))
            : _createCommentVNode("", true),
          _createElementVNode("div", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.unifiedResults.slice(
            0,
            _ctx.PAGE_SIZE * _ctx.resultsPage
          ), (result, index) => {
              return (_openBlock(), _createElementBlock("div", {
                key: index,
                class: "flex flex-col w-full px-4 py-3 transition-colors duration-75 hover:bg-light"
              }, [
                _createVNode(_component_router_link, {
                  to: _ctx.resultTypeRouteMapping[result.type](result.data.id)
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", null, [
                      (result.type === 'user')
                        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                            _createVNode(_component_UserAvatar, {
                              size: 'sm',
                              url: result.data.profile_picture
                            }, null, 8, ["url"]),
                            _createElementVNode("span", null, _toDisplayString(result.data.full_name), 1)
                          ]))
                        : (result.type === 'post')
                          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                              _createVNode(_component_PostOutlineIcon, { class: "light-icon" }),
                              _createElementVNode("p", null, _toDisplayString(_ctx.getPostPreview(result.data)), 1)
                            ]))
                          : (result.type === 'section')
                            ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                                _createElementVNode("span", null, [
                                  _createElementVNode("b", null, _toDisplayString(result.data.name), 1),
                                  _createTextVNode(" → Sezione ")
                                ])
                              ]))
                            : (_openBlock(), _createElementBlock("div", _hoisted_9, [
                                _createElementVNode("span", null, [
                                  _createElementVNode("b", null, _toDisplayString(result.data.name), 1),
                                  _createTextVNode(" → Sottosezione ")
                                ])
                              ]))
                    ])
                  ]),
                  _: 2
                }, 1032, ["to"])
              ]))
            }), 128)),
            _withDirectives(_createElementVNode("div", null, [
              _createVNode(_component_TButton, {
                class: "w-full",
                variant: 'text',
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onMoreResults()))
              }, {
                default: _withCtx(() => [
                  _createTextVNode("Mostra altri risultati")
                ]),
                _: 1
              })
            ], 512), [
              [_vShow, _ctx.unifiedResults.length > _ctx.PAGE_SIZE * _ctx.resultsPage]
            ])
          ])
        ]))
      : _createCommentVNode("", true)
  ])), [
    [_directive_click_outside, () => (_ctx.showResults = false)]
  ])
}