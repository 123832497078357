
import { TCard } from "@variantjs/vue";
import { defineComponent, PropType } from "@vue/runtime-core";
import { v4 as uuid4 } from "uuid";
import VideoPlusIcon from "vue-material-design-icons/VideoPlus.vue";
// import UploadIcon from "vue-material-design-icons/Upload.vue";
import LoadingSpinner from "./LoadingSpinner.vue";

export default defineComponent({
  name: "VideoInput",
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      baseId: "a" + uuid4(),
    };
  },
  methods: {
    onFileChange(event: { target: { files: FileList | null } }) {
      const file = event.target.files?.[0];
      if (file) {
        this.$emit("file", file);
      }
      // reset the input
      (event.target as any).value = "";
    },
  },
  computed: {},
  components: { TCard, VideoPlusIcon, LoadingSpinner },
});
