
import { defineComponent, PropType } from "@vue/runtime-core";
import { Section } from "@/models/interfaces";
import MenuDownIcon from "vue-material-design-icons/MenuDown.vue";
import MenuUpIcon from "vue-material-design-icons/MenuUp.vue";
import { TButton } from "@variantjs/vue";
export default defineComponent({
  // eslint-disable-next-line vue/multi-word-component-names
  name: "SectionListItem",
  props: {
    section: {
      type: Object as PropType<Section>,
      required: true,
    },
    subSections: {
      type: Array as PropType<Section[]>,
      default: () => [],
    },
    sub: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  data() {
    return {
      expanded: false,
    };
  },
  components: { MenuDownIcon, MenuUpIcon, TButton },
  methods: {},
  computed: {
    hasSubSections() {
      return this.subSections.length > 0;
    },
    routeName() {
      const currentRouteParams = this.$route.params;
      return currentRouteParams.userId ? "UserProfileSection" : "SectionHome";
    },
  },
});
