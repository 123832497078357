import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "relative" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_click_outside = _resolveDirective("click-outside")!

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.$slots.activator?.())
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('toggleExpanded')))
        }, [
          _renderSlot(_ctx.$slots, "activator")
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(["absolute z-50 w-max overflow-y-auto transition-all duration-100 ease-in transform bg-white mt-1.5 card shadow-popup", {
        'opacity-0 scale-95 invisible max-h-97': !_ctx.expanded,
        'max-h-97 opacity-100 scale-100': _ctx.expanded,
        'origin-top-left left-0': _ctx.placement === 'left',
        'origin-top-right right-0': _ctx.placement === 'right',
      }])
    }, [
      _renderSlot(_ctx.$slots, "default")
    ], 2)
  ])), [
    [_directive_click_outside, () => (_ctx.expanded ? _ctx.$emit('toggleExpanded') : undefined)]
  ])
}