
import { TCard } from "@variantjs/vue";
import { TButton } from "@variantjs/vue";

import { defineComponent, PropType } from "@vue/runtime-core";
import { Video } from "../../models";
import VideoIcon from "vue-material-design-icons/Video.vue";
import CloseIcon from "vue-material-design-icons/Close.vue";

export default defineComponent({
  name: "VideoThumbnail",
  props: {
    video: {
      type: Object as PropType<Video>,
      required: true,
    },
  },
  methods: {},
  computed: {
    base64Thumbnail() {
      return `data:image/png;base64,${this.video.thumbnail}`;
    },
    title() {
      // preview of the title cut to 50 chars
      return (
        this.video.title.trim().slice(0, 25) +
        (this.video.title.length > 25 ? "..." : "")
      );
    },
  },
  components: { TCard, VideoIcon, TButton, CloseIcon },
});
