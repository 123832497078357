
import UserAvatar from "../UserAvatar.vue";
import { Post } from "@/models";
import {
  defineComponent,
  PropType,
  defineAsyncComponent,
} from "@vue/runtime-core";
import { useMainStore } from "../../stores/mainStore";
import { mapStores } from "pinia";
import { TButton } from "@variantjs/vue";
import DeleteIcon from "vue-material-design-icons/Delete.vue";

export default defineComponent({
  name: "SharedPost",
  props: {
    post: {
      type: Object as PropType<Post>,
      required: true,
    },
  },
  beforeCreate() {
    // dynamically import specialized components to prevent circular imports

    (this.$options.components as any).PostPreview = defineAsyncComponent(
      () => import("./PostPreview.vue")
    );
  },
  methods: {},
  computed: {
    ...mapStores(useMainStore),
    isPostOwner() {
      return this.post.author.id == this.mainStore.user?.id;
    },
    postAuthorProfileRoute() {
      return {
        name: "UserProfile",
        params: {
          userId: this.post.author.id,
        },
      };
    },
    sharedPostRoute() {
      return {
        name: "PostDetail",
        params: {
          postId:
            typeof this.post.shared === "object"
              ? this.post.shared.id
              : this.post.shared,
        },
      };
    },
  },
  components: { UserAvatar, DeleteIcon, TButton },
});
