
import { defineComponent, PropType } from "@vue/runtime-core";
import BaseView from "../BaseView.vue";
import { TabItem } from "@/const";
import { TButton } from "@variantjs/vue";
import PlusIcon from "vue-material-design-icons/Plus.vue";
import FullDialog from "@/components/ui/FullDialog.vue";
import MDDialog from "@/components/ui/MDDialog.vue";
import PostEditor from "@/components/PostEditor/PostEditor.vue";
import { useMainStore } from "@/stores/mainStore";
import { computed, Ref, ref, watch } from "vue";
import useSections from "@/composables/useSections";
import usePostsByType from "@/composables/usePosts";
import UserAvatar from "../../components/UserAvatar.vue";
import { PostType } from "../../models";
import PostList from "../../components/PostList.vue";
import useUser from "../../composables/useUser";
import AccountPlusIcon from "vue-material-design-icons/AccountPlus.vue";
import AccountMinusIcon from "vue-material-design-icons/AccountMinus.vue";
import AccountGroupIcon from "vue-material-design-icons/AccountGroup.vue";
import SortIcon from "vue-material-design-icons/Sort.vue";
import CogIcon from "vue-material-design-icons/Cog.vue";
import SectionSorter, {
  SortOption,
} from "../../components/Sections/SectionSorter.vue";
import DropdownMenu from "../../components/ui/DropdownMenu.vue";

export default defineComponent({
  name: "UserProfile",
  props: {
    postType: {
      type: String as PropType<PostType>,
      default: "mixed",
      required: false,
    },
    sectionId: {
      type: String,
      required: false,
    },
    userId: {
      type: String,
      required: true,
    },
  },
  methods: {
    onShowPostEditor() {
      this.showPostEditor = true;
    },
    isActiveTab(tabValue: { name: string; params: Record<string, string> }) {
      return (
        this.$route.name === tabValue.name &&
        // TODO refactor this
        Object.values(this.$route.params)[1] ===
          Object.values(tabValue.params)[0]
      );
    },
  },
  setup(props) {
    const mainStore = useMainStore();

    const PROFILE_TABS: TabItem[] = [
      {
        label: "Divulgativi",
        value: {
          name: "UserProfile",
          params: { postType: "dissemination" },
        },
      },
      {
        label: "Personali",
        value: {
          name: "UserProfile",
          params: { postType: "personal" },
        },
      },
      {
        label: "Misti",
        value: {
          name: "UserProfile",
          params: { postType: "mixed" },
        },
      },
    ];

    const tabs: Ref<TabItem[]> = ref(PROFILE_TABS);

    const { fetchSections, currentSection } = useSections();

    const { fetchPosts } = usePostsByType();
    const { currentUser, currentUserFollowers, currentUserFollowed } =
      useUser();

    const posts = computed(() => mainStore.posts);
    const isOwnProfile = computed(() => mainStore.user?.id == props.userId);

    const sectionsSortOption = ref(null) as Ref<SortOption | null>;

    const showSortSectionsDialog = ref(false);
    const onSortSections = () => {
      showSortSectionsDialog.value = true;
    };

    // watch for props changes to both sectionId and postType
    watch(
      () => [props.sectionId, props.postType, props.userId],
      ([newSectionId, newPostType, newUserId], oldProps) => {
        const [oldSectionId, oldPostType, oldUserId] = oldProps ?? [
          undefined,
          undefined,
          undefined,
        ];

        // TODO check for validity of newSectionId and newPostType
        const postFilters = {
          ...(newSectionId ? { section: newSectionId } : {}),
          ...(newPostType ? { post_type: newPostType as PostType } : {}),
          author: newUserId,
        };

        const sectionFilters = {
          ...(newSectionId ? { parent: newSectionId } : {}),
          relevant_for: newUserId,
        };

        const shouldRefetch = !(
          newSectionId === oldSectionId &&
          newPostType === oldPostType &&
          newUserId === oldUserId
        );

        if (shouldRefetch) {
          fetchPosts(postFilters);
          fetchSections(sectionFilters);
          if (isOwnProfile.value) {
            mainStore.getSectionSorting().then((res) => {
              sectionsSortOption.value = res.sort_type;
            });
          }

          tabs.value = newSectionId ? [] : PROFILE_TABS;
        }
      },
      { immediate: true }
    );

    const showPostEditor = ref(false);

    const onFollow = async (userId: string, isFollowed: boolean) => {
      if (currentUser.value) {
        await mainStore.followUser(userId, isFollowed);
        // refetch current user & followers
        // TODO simplify
        currentUser.value = await mainStore.getUserById(currentUser.value.id);
        currentUserFollowers.value = await mainStore.getUserFollowersByUserId(
          currentUser.value.id
        );
        currentUserFollowed.value = await mainStore.getUserFollowedByUserId(
          currentUser.value.id
        );
      }
    };

    const followersExpanded = ref(false);
    const followedExpanded = ref(false);

    return {
      tabs,
      showPostEditor,
      posts,
      currentSection,
      currentUser,
      currentUserFollowers,
      currentUserFollowed,
      isOwnProfile,
      onSortSections,
      showSortSectionsDialog,
      mainStore,
      sectionsSortOption,
      onFollow,
      followersExpanded,
      followedExpanded,
    };
  },

  components: {
    BaseView,
    TButton,
    PlusIcon,
    FullDialog,
    PostEditor,
    UserAvatar,
    PostList,
    AccountPlusIcon,
    AccountMinusIcon,
    AccountGroupIcon,
    SortIcon,
    MDDialog,
    SectionSorter,
    CogIcon,
    DropdownMenu,
  },
});
