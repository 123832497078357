
import { TTextarea } from "@variantjs/vue";
import { defineComponent, PropType } from "@vue/runtime-core";
import { Post } from "@/models";
import PostPreview from "./Post/PostPreview.vue";
import { mapStores } from "pinia";
import { useMainStore } from "@/stores/mainStore";
import MDDialog from "./ui/MDDialog.vue";
import TextEditor from "./ui/TextEditor.vue";
import { TButton } from "@variantjs/vue";
import PostOutlineIcon from "vue-material-design-icons/PostOutline.vue";
import { getBlankPostComment, getSharedPost } from "../models/factories";
import FactCheckVoter from "./Post/FactCheckVoter.vue";
import { showSuccessNotification } from "../utils";
import HandCoinIcon from "vue-material-design-icons/HandCoin.vue";

export default defineComponent({
  name: "PostList",
  props: {
    posts: {
      type: Array as PropType<Post[]>,
      default: () => [],
    },
  },
  data() {
    return {
      sharingPost: null as null | Post,
      givingCoinPost: null as null | Post,
      coinsToGive: 0,
      factCheckingPost: null as null | Post,
      showingSourcesPost: null as null | Post,
      dirtyFactCheck: 0,
      sharePostText: "",
      loadingAction: false,
      creatingCommentForPosts: new Set<string>(),
      factCheckComment: "",
    };
  },
  methods: {
    stringToTextWithLinks(text: string) {
      // turns a plain text string into an html paragraph where links are turned to <a> elements
      // and newlines are turned to <br> elements
      const urlRegex = /(https?:\/\/[^\s]+)/g;
      const urlMatches = text.match(urlRegex);
      if (urlMatches) {
        urlMatches.forEach((url) => {
          text = text.replace(
            url,
            `<a class="text-link" href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`
          );
        });
      }
      return text.replace(/\n/g, "<br>");
    },
    onShare(post: Post) {
      if (!this.mainStore.userId) {
        this.$router.push({ name: "Login" });
        return;
      }
      this.sharingPost = post;
    },
    onGiveCoin(post: Post) {
      if (!this.mainStore.userId) {
        this.$router.push({ name: "Login" });
        return;
      }
      this.givingCoinPost = post;
    },
    async onLike(post: Post) {
      if (!this.mainStore.userId) {
        this.$router.push({ name: "Login" });
        return;
      }
      await this.mainStore.likePost(post.id, !!post.liked);
    },
    async onSave(post: Post) {
      if (!this.mainStore.userId) {
        this.$router.push({ name: "Login" });
        return;
      }
      await this.mainStore.savePost(post.id, !!post.saved);
    },
    async onDelete(post: Post) {
      if (
        !confirm(
          "Sei sicuro di voler eliminare questo post? Quest'azione è irreversibile."
        )
      )
        return;
      await this.mainStore.deletePost(post.id);
      showSuccessNotification("Post eliminato con successo");
    },
    async onCreateComment(postId: string, text: string) {
      if (!this.mainStore.userId) {
        this.$router.push({ name: "Login" });
        return;
      }
      this.creatingCommentForPosts.add(postId);
      try {
        await this.mainStore.createComment(postId, getBlankPostComment(text));
      } finally {
        this.creatingCommentForPosts.delete(postId);
      }
    },
    async doShare() {
      this.loadingAction = true;
      try {
        const post = getSharedPost(
          this.sharingPost?.id ?? "",
          this.sharePostText
        );
        await this.mainStore.createPost(post);
        this.sharingPost = null;
        showSuccessNotification("Post condiviso");
      } finally {
        this.loadingAction = false;
      }
    },
    async doGiveCoin() {
      if (!this.givingCoinPost || this.coinsToGive <= 0) return;
      this.loadingAction = true;
      try {
        // TODO implement
        this.coinsToGive = 0;
        this.givingCoinPost = null;
        showSuccessNotification("Monete donate");
      } finally {
        this.loadingAction = false;
      }
    },
    async doFactCheck() {
      if (!this.factCheckingPost || this.factCheckComment.trim().length === 0)
        return;
      try {
        this.loadingAction = true;
        await this.mainStore.factCheckPost(
          this.factCheckingPost.id,
          this.dirtyFactCheck
        );
        if (this.factCheckComment) {
          await this.mainStore.createComment(
            this.factCheckingPost.id,
            getBlankPostComment(this.factCheckComment, "fact_check")
          );
          this.factCheckComment = "";
        }
        this.factCheckingPost = null;
        showSuccessNotification("Voto salvato");
      } finally {
        this.loadingAction = false;
      }
    },
    onFactCheck(post: Post) {
      this.factCheckingPost = post;
      if (post.my_fact_check_vote) {
        this.dirtyFactCheck = post.my_fact_check_vote;
      }
    },
    onShowSources(post: Post) {
      this.showingSourcesPost = post;
    },
  },
  computed: {
    ...mapStores(useMainStore),
  },
  components: {
    PostPreview,
    MDDialog,
    TextEditor,
    TButton,
    FactCheckVoter,
    PostOutlineIcon,
    TTextarea,
    HandCoinIcon,
  },
});
