
import { defineComponent, PropType } from "@vue/runtime-core";
import { MDCDialog } from "@material/dialog";
import { v4 as uuid4 } from "uuid";
import { TButton } from "@variantjs/vue";
import CloseIcon from "vue-material-design-icons/Close.vue";
import { mediaQueryMixin } from "../../mixins";
export default defineComponent({
  name: "MDDialog",
  props: {
    open: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    large: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    okOnly: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  mixins: [mediaQueryMixin],
  watch: {
    open() {
      if (this.open) {
        this.mdcComponent?.open();
      } else {
        this.mdcComponent?.close();
      }
    },
  },
  mounted() {
    const dialog = new MDCDialog(
      document.querySelector("#" + this.dialogId) as Element
    );
    this.mdcComponent = dialog;
    dialog.listen("MDCDialog:closed", () => {
      this.$emit("closeDialog");
    });
    if (this.open) {
      dialog.open();
    }
  },
  data() {
    return {
      mdcComponent: null as MDCDialog | null,
      baseUuid: uuid4(),
    };
  },
  methods: {},
  computed: {
    dialogId() {
      return "a" + this.baseUuid + "-mdc-dialog";
    },
  },
  components: { TButton, CloseIcon },
});
