
import { defineComponent, PropType } from "@vue/runtime-core";
import BaseView from "../BaseView.vue";
import { TabItem } from "@/const";
import { TButton } from "@variantjs/vue";
import PlusIcon from "vue-material-design-icons/Plus.vue";
import { mapStores } from "pinia";
import FullDialog from "@/components/ui/FullDialog.vue";
import PostEditor from "@/components/PostEditor/PostEditor.vue";
import { useMainStore } from "@/stores/mainStore";
import { computed, Ref, ref, watch } from "vue";
import useSections from "@/composables/useSections";
import usePostsByType from "@/composables/usePosts";
import UserAvatar from "../../components/UserAvatar.vue";
import HomeIcon from "vue-material-design-icons/Home.vue";
import { PostType } from "../../models";
import PostList from "../../components/PostList.vue";
import { VueEternalLoading, LoadAction } from "@ts-pro/vue-eternal-loading";

export default defineComponent({
  name: "BaseHome",
  props: {
    postType: {
      type: String as PropType<PostType>,
      required: false,
    },
    sectionId: {
      type: String,
      required: false,
    },
  },
  methods: {
    onShowPostEditor() {
      this.showPostEditor = true;
    },
    isActiveTab(tabValue: { name: string; params: Record<string, string> }) {
      return (
        this.$route.name === tabValue.name &&
        // TODO refactor this
        Object.values(this.$route.params)[0] ===
          Object.values(tabValue.params)[0]
      );
    },
  },
  computed: {
    ...mapStores(useMainStore),
  },
  setup(props) {
    const mainStore = useMainStore();

    const HOME_TABS: TabItem[] = [
      {
        label: "Home divulgativa",
        value: {
          name: "Home",
          params: { postType: "dissemination" },
        },
      },
      {
        label: "Home personale",
        value: {
          name: "Home",
          params: { postType: "personal" },
        },
      },
      {
        label: "Home mista",
        value: {
          name: "Home",
          params: { postType: "mixed" },
        },
      },
    ];

    const tabs: Ref<TabItem[]> = ref(HOME_TABS);

    const { fetchSections, currentSection } = useSections();

    const { fetchPosts } = usePostsByType();

    const posts = computed(() => mainStore.posts);
    const currentUser = computed(() => mainStore.user);

    const isInitialInfiniteLoad = ref(false);
    const currentPage = ref(1);

    const onLoadMore = async ({ loaded, noMore, error }: LoadAction) => {
      try {
        currentPage.value++;
        const moreResults = await fetchPosts(
          {
            post_type:
              (props.postType as any) === "mixed" ? undefined : props.postType,
            section: props.sectionId,
          },
          currentPage.value
        );
        // console.log("moreResults", moreResults);
        if (!moreResults) {
          noMore();
        } else {
          loaded();
        }
      } catch {
        error();
      }
    };

    watch(
      () => currentPage.value,
      (newPage) => {
        if (newPage === 1) {
          isInitialInfiniteLoad.value = true;
        }
      }
    );

    // watch for props changes to both sectionId and postType
    watch(
      () => [props.sectionId, props.postType],
      ([newSectionId, newPostType], oldProps) => {
        const [oldSectionId, oldPostType] = oldProps ?? [undefined, undefined];
        // TODO check for validity of newSectionId and newPostType

        if (newSectionId && newSectionId != oldSectionId) {
          currentPage.value = 1;
          // if inside of a section, remove the tabs, loads its sub-sections and posts
          tabs.value = [];
          fetchPosts({ section: newSectionId as string }, 1);
          fetchSections({ parent: newSectionId as string });
        } else if (newPostType && newPostType != oldPostType) {
          currentPage.value = 1;
          // if inside of a post type, show the tabs and load posts of that type
          tabs.value = HOME_TABS;
          fetchPosts(
            newPostType === "mixed"
              ? undefined
              : { post_type: newPostType as PostType },
            1
          );
          fetchSections();
        }
      },
      { immediate: true }
    );

    const showPostEditor = ref(false);

    return {
      tabs,
      showPostEditor,
      posts,
      currentSection,
      currentUser,
      onLoadMore,
      isInitialInfiniteLoad,
    };
  },

  components: {
    BaseView,
    TButton,
    PlusIcon,
    FullDialog,
    PostEditor,
    UserAvatar,
    HomeIcon,
    PostList,
    VueEternalLoading,
  },
});
