
import { defineComponent, PropType } from "@vue/runtime-core";
import SectionList from "@/components/Sections/SectionList.vue";
import { TButton } from "@variantjs/vue";
import { mapStores } from "pinia";
import { useMainStore } from "@/stores/mainStore";
import { TabItem } from "@/const";
export default defineComponent({
  name: "BaseView",
  props: {
    tabs: {
      type: Array as PropType<TabItem[]>,
      default: () => [],
    },
  },
  async created() {
    //wrapWithLoading(async () => await this.mainStore.getSections());
  },
  data() {
    return {
      showPostEditor: false,
    };
  },
  methods: {
    isActiveTab(tabName: string) {
      return this.$route.name === tabName;
    },
    onShowPostEditor() {
      this.showPostEditor = true;
    },
  },
  computed: {
    ...mapStores(useMainStore),
    shownSections() {
      return this.mainStore.sections; //.filter((s) => s.parent === null);
    },
  },
  components: {
    SectionList,
    //TButton,
    // PlusIcon,
    // PostEditor,
    // FullDialog,
  },
});
