import { WatchCallback, ref, watch } from "vue";
import { useMainStore } from "@/stores/mainStore";
import { wrapWithLoading } from "@/utils";
import { PreviewUser, Section, User } from "@/models";
import { SectionFilter } from "@/const";
import { useRoute } from "vue-router";

export default function useSections() {
  const mainStore = useMainStore();
  const route = useRoute();

  //   const userId = route.params.userId as string;
  const currentUser = ref(null as User | null);
  const currentUserFollowers = ref([] as PreviewUser[]);
  const currentUserFollowed = ref([] as PreviewUser[]);

  const onUserIdChange = (callback: WatchCallback<any>) =>
    watch(() => route.params.userId, callback, { immediate: true });

  // Keep currentSection up to date
  onUserIdChange(async (userId) => {
    if (userId) {
      currentUser.value = await mainStore.getUserById(userId);
      currentUserFollowers.value = await mainStore.getUserFollowersByUserId(
        userId
      );
      currentUserFollowed.value = await mainStore.getUserFollowedByUserId(
        userId
      );
    } else {
      currentUser.value = null;
      currentUserFollowers.value = [];
    }
  });

  return {
    currentUser,
    currentUserFollowers,
    currentUserFollowed,
  };
}
