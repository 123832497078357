
import { TButton, TInput } from "@variantjs/vue";
import { defineComponent, PropType } from "@vue/runtime-core";
import { mapStores } from "pinia";
import { useMainStore } from "../stores/mainStore";
import { registrationErrors } from "../const";
import { showSuccessNotification } from "../utils";
import CalendarInput from "../components/ui/CalendarInput.vue";
import EyeIcon from "vue-material-design-icons/Eye.vue";
import EyeOffIcon from "vue-material-design-icons/EyeOff.vue";

export default defineComponent({
  name: "RegisterView",
  props: {},
  methods: {
    async onSubmit() {
      try {
        await this.mainStore.registerUser(
          this.username,
          this.email,
          this.password,
          this.passwordConfirm,
          this.birthDate as Date
        );
        this.$router.push({ name: "Login" });
        showSuccessNotification("Account creato con successo");
      } catch (e: any) {
        console.error(e);
        if (e.response?.status === 400) {
          this.errors = e.response.data;
        } else {
          throw e;
        }
      }
    },
  },
  data() {
    return {
      username: "",
      email: "",
      password: "",
      passwordConfirm: "",
      birthDate: null as Date | null,
      errors: {} as Record<string, string[]>,
      registrationErrors,
      showPassword: false,
      showConfirmPassword: false,
    };
  },
  computed: {
    ...mapStores(useMainStore),
  },
  components: { TInput, TButton, CalendarInput, EyeOffIcon, EyeIcon },
});
