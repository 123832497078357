import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "flex items-center" }
const _hoisted_2 = ["for"]
const _hoisted_3 = ["onInput", "id", "value", "checked", "disabled"]
const _hoisted_4 = { class: "flex mx-auto space-x-2 items-top" }
const _hoisted_5 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option, index) => {
      return (_openBlock(), _createElementBlock("label", {
        for: _ctx.id + '-input-' + index,
        class: _normalizeClass(["relative flex flex-grow max-h-screen px-4 py-2 overflow-hidden overflow-x-hidden overflow-y-hidden text-sm font-medium text-center text-gray-600 border border-gray-400 rounded-sm cursor-pointer min-w-fit overflow-ellipsis whitespace-nowrap items-top", {
        'pl-4.5 pr-3.5': index === 0,
        'pl-4 pr-4.5': index === _ctx.options.length - 1,
        'rounded-r-none': index < _ctx.options.length - 1,
        'rounded-l-none border-l-0 pl-4.5': index !== 0,
        'bg-primary bg-opacity-20 ': option.value == _ctx.modelValue,
        'hover:text-gray-800 hover:bg-light': option.value != _ctx.modelValue,
      }]),
        key: _ctx.id + '-option-' + index
      }, [
        _createElementVNode("input", {
          onInput: ($event: any) => (_ctx.onInput(option.value, $event)),
          class: "invisible w-0 h-0",
          type: "radio",
          id: _ctx.id + '-input-' + index,
          value: option.value,
          checked: option.value == _ctx.modelValue,
          disabled: _ctx.disabled
        }, null, 40, _hoisted_3),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("p", {
            class: "",
            innerHTML: option.label
          }, null, 8, _hoisted_5)
        ])
      ], 10, _hoisted_2))
    }), 128))
  ]))
}