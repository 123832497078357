import { createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "overflow-hidden rounded-full",
    style: _normalizeStyle(_ctx.sizeStyle)
  }, [
    _createElementVNode("img", {
      src: _ctx.urlWithFallback,
      alt: "Avatar"
    }, null, 8, _hoisted_1)
  ], 4))
}