
import UserAvatar from "../UserAvatar.vue";
import { TButton } from "@variantjs/vue";

import CameraIcon from "vue-material-design-icons/Camera.vue";
import { v4 as uuid4 } from "uuid";
import MDDialog from "./MDDialog.vue";

import { defineComponent, PropType } from "@vue/runtime-core";
import { mapStores } from "pinia";
import { useMainStore } from "../../stores/mainStore";
export default defineComponent({
  name: "AvatarSelector",
  props: {
    url: {
      type: String as PropType<string>,
      required: true,
    },
  },
  data() {
    return {
      baseId: "a" + uuid4(),
      selectedFile: null as File | null,
      loading: false,
    };
  },
  methods: {
    onFileChange(event: { target: { files: FileList | null } }) {
      const file = event.target.files?.[0];
      if (!file) {
        return;
      }
      this.selectedFile = file;
    },
    async confirm() {
      this.loading = true;
      try {
        await this.mainStore.updateMe({
          profile_picture: this.selectedFile as File,
        });
        window.location.reload();
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
        this.selectedFile = null;
      }
    },
  },
  computed: {
    ...mapStores(useMainStore),
    urlWithFallback() {
      return this.url || "https://via.placeholder.com/150";
    },
    selectedImageUrl() {
      return this.selectedFile ? URL.createObjectURL(this.selectedFile) : "";
    },
  },
  components: { CameraIcon, MDDialog, UserAvatar, TButton },
});
