import axios from "axios";
import { defineStore } from "pinia";
import { Post, Section, User } from "../models";

export const useSearchStore = defineStore({
  id: "search",
  state: () => ({
    query: "",
    results: {
      posts: [],
      sections: [],
      subsections: [],
      users: [],
    } as {
      posts: Post[];
      sections: Section[];
      subsections: Section[];
      users: User[];
    },
    loading: false,
    error: null as any,
  }),
  getters: {
    // hasResults: (state) => {
    //   return (
    //     state.results.posts.length > 0 ||
    //     state.results.sections.length > 0 ||
    //     state.results.subsections.length > 0 ||
    //     state.results.users.length > 0
    //   );
    // },
    unifiedResults: (state) =>
      [
        ...state.results.sections.map((s) => ({ type: "section", data: s })),
        ...state.results.subsections.map((s) => ({
          type: "subsection",
          data: s,
        })),
        ...state.results.users
          .filter((u) => u.user_type === "disseminator")
          .map((u) => ({ type: "user", data: u })),
        ...state.results.posts
          .filter((p) => p.post_type === "dissemination")
          .map((p) => ({ type: "post", data: p })),
        ...state.results.users
          .filter((u) => u.user_type === "member")
          .map((u) => ({ type: "user", data: u })),
        ...state.results.posts
          .filter((p) => p.post_type === "personal")
          .map((p) => ({ type: "post", data: p })),
      ] as (
        | {
            type: "post";
            data: Post;
          }
        | {
            type: "section";
            data: Section;
          }
        | {
            type: "subsection";
            data: Section;
          }
        | {
            type: "user";
            data: User;
          }
      )[],
  },
  actions: {
    setQuery(newQuery: string) {
      this.query = newQuery;
    },
    setLoading(newLoading: boolean) {
      this.loading = newLoading;
    },
    async search() {
      this.loading = true;
      try {
        const response = await axios(`/search/?q=${this.query}`);
        const data = response.data;
        this.results = data;
      } catch (error) {
        this.error = error;
      } finally {
        this.loading = false;
      }
    },
  },
});
